import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function CreateNewWallet({changeStepProcess, closeProductModal}) {
  return (
    <div>
        <h2 className="text-center">New Wallet</h2><br />

        <p>In order to receive AI Tokens you would be required to have a AIT Wallet.</p><br />

        <p>Please note that all the steps is listed in the link below and do you require any info please contact info@aitokensa.co.za:</p>

        - <a href="https://aitokensa.co.za/create-ai-wallet/" target="_BLANK" ><u>Create AI Wallet</u></a>

        {/* Navigation Buttons */}
        <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(3)}}>I now have an AIT Wallet</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(1)}}>Back</Button>
        </div>
            
    </div>
  )
}

CreateNewWallet.propTypes = {
    closeProductModal: PropTypes.func,
    changeStepProcess:PropTypes.func,
}

export default CreateNewWallet