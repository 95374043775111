import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { display } from '@mui/system';

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import PDFTransactions from "../../PDF/PDFTransactions";
import { PDFDownloadLink } from '@react-pdf/renderer'

function TransactionsReport() {

  const[displayNotifications, setDisplayNotifications] =  React.useState(true);
  const{auth,user} = React.useContext(UserContext)

  const [transactions, setTransactions] = useState([])
  const [usdtProductAmount, setUsdtProductAmount] = useState(0)
  const [btcProductAmount, setBTCProductAmount] = useState(0)
  const[name, setName] =  React.useState('');
  const[userid, setUserid] =  React.useState('');
  const[useDate, setUseDate] =  React.useState('');
  const[totalZAR, setTotalZAR] =  React.useState(0);
  const[totalUSDT, setTotalUSDT] =  React.useState(0);
  const[totalBTC, setTotalBTC] =  React.useState(0);
  const[filterButtonChecked, setFilterButtonChecked] =  React.useState(false);

  const[IDNumber, setIDNumber] =  React.useState('');
  const[exchangeRates, setExchangeRates] =  React.useState(null);
  
  
  const[dateFrom, setDateFrom] =  React.useState(null);
  const[dateTo, setDateTo] =  React.useState(null);
  const[appliedFilter, setAppliedFilter] =  React.useState(false);
  const [currencyDisplay, setCurrencyDisplay] = useState({
    '1': 'USDT',
    '2': 'ZAR',
    '3': 'BTC',
  })
const [countryCurrencyDisplay, setCountryDisplay] = useState('ZAR')

  React.useEffect(()=>{
  
    if(auth && user)
    {
      getUserDetails(user.user.userid)
      getExchangeRates();
      setName(user.user.name + ' ' + user.user.lastname)
      setUserid(user.user.userid)
      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let newstring = ''
      if(month<10)
        newstring = '0' + month
      else
        newstring = month
      setUseDate(year + '-' + newstring + '-' + date)

      getPdfDownloadInformation(user.user.userid)

    }

  },[auth])

    const getUserDetails = async (userid) => {
      var userObj = {
        userid: userid
      }
    
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
          var mainString = res.data.info.country
          setIDNumber(res.data.info.id_num);
          if(mainString && mainString.includes("Namibia"))
          {
            setCurrencyDisplay({
              '1': 'USDT',
              '2': 'NAD',
              '3': 'BTC',
            })
            setCountryDisplay('NAD')
          }else{
           
          }
        }
      });
    }

    const getPdfDownloadInformation = async(userUserid) => {
      var userObj = {
        userid: userUserid,
        dateFrom:dateFrom,
        dateTo: dateTo
      }

      getUserBalances(userUserid)

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-transactions-report`,userObj).then((res)=>{
        if(res.data.status == 200)
        {
          setTransactions(res.data.transactions)
        }
      })

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sum-of-user-products`,userObj).then((res)=>{
        if(res.data.status == 200)
        {
          setUsdtProductAmount(res.data.usdt)
          setBTCProductAmount(res.data.btc)
        }
      })

    }

    const getUserBalances = async(userid) => {
      var userObj = {
        userid: userid,
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance-report`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
        
          setTotalZAR(res.data.currencyInfo[1].balance)
          setTotalUSDT(res.data.currencyInfo[0].balance)
          setTotalBTC(res.data.currencyInfo[2].balance)
        }
      })

      return true;
    }

    const handleRadioButtonChange = async(event) => {
   
      if(event === 'true')
      {
       
        setFilterButtonChecked(false)
      }

      if(event === 'false')
      {
       
        setFilterButtonChecked(true)
      }
    
    }

    const applyDateFilter = async() => {
      await getPdfDownloadInformation(userid)
      setAppliedFilter(true)
    }

    const applyFromDate = async(date) => {
      setAppliedFilter(false)
      setDateFrom(date)
    }

    const applyToDate = async(date) => {
      setDateTo(date)
      setAppliedFilter(false)
    }


    const getExchangeRates = async () => {
      await axios.get(`${process.env.REACT_APP_URL}exchange-rates`,{}).then(async (res)=>{
          if(res.data.status == 200)
          {
            console.log(res.data.rates)
            setExchangeRates(res.data.rates)
          }
          
      })
  };

  return (
    <div className='mt-8'>
          
      <div>My <span className="primary-color">Transactions <FontAwesomeIcon className="pl-2" icon={faCaretDown} size="xl"  /></span></div>

      <div className="mt-4 mb-4">
        <div className="mb-2">
          <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={filterButtonChecked === 'true' ? 'checked' : ''}  className="btn-check" name="vbtn-radio"  value={filterButtonChecked} id="allOption" />
          <label style={{borderRadius: "70px", padding: "5px 10px", marginBottom: "20px"}} className={'mr-8' + (filterButtonChecked ? ' c1-primary-btn-active' : '')} htmlFor="allOption">Custom Filter</label>
        </div>

        {filterButtonChecked ?
          <div>
            <div className="mb-2">
              <label style={{borderRadius: "70px", padding: "5px 10px", marginBottom: "20px"}} className={'mr-2'} htmlFor="allOption">From:</label>
              <input style={{ background: "transparent", padding: "3px", colorScheme: "dark", border: "1px solid #fff", borderRadius: "5px", color: "#fff"}} type="date" className="form-control" name="vbtn-radio" onChange={(e) => {applyFromDate(e.target.value)}}  value={dateFrom} id="allOption"  />
            </div>

            <div className="mb-2">
              <label style={{borderRadius: "70px", padding: "5px 10px", marginBottom: "20px"}} className={'mr-2'} htmlFor="allOption">To:</label>
              <input style={{ background: "transparent", padding: "3px", colorScheme: "dark", border: "1px solid #fff", borderRadius: "5px", color: "#fff"}} type="date" className="form-control" name="vbtn-radio" onChange={(e) => {applyToDate(e.target.value)}}  value={dateTo} id="allOption"  />
            </div>

            <button disabled={appliedFilter ? 'disabled' : ''} style={{borderRadius: "70px", padding: "5px 10px", marginBottom: "20px"}} type="button" className={`${appliedFilter ? 'bg-green-500 color-light' : 'c1-primary-btn-active'} btn form-control`} onClick={(e) => applyDateFilter()}>{appliedFilter ? 'Applied' : 'Apply Filter'}</button>
          </div>

        : ''}
          
      </div>

      <PDFDownloadLink document={<PDFTransactions title="Transaction Report" name={name} currency={currencyDisplay} countryCurrencyDisplay={countryCurrencyDisplay}  info={transactions} userid={userid} date={useDate} totalUSDT={totalUSDT} totalBTC={totalBTC} totalZAR={totalZAR} idnumber={IDNumber} applyFilter={filterButtonChecked} dateFrom={dateFrom} dateTo={dateTo} usdtProductTotal={usdtProductAmount} btcProductTotal={btcProductAmount} exchangeRates={exchangeRates} />} fileName={"Transactions-" + useDate} >
        {({loading }) => (loading ? "Loading..." : <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
          Download Report
        </button>)}
      </PDFDownloadLink>

    </div>
  )
}

export default TransactionsReport