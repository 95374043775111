import React, {useState, useEffect} from "react";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ProductConfirmModal from '../../Modals/ProductConfirmModal';
import { fas } from "@fortawesome/free-solid-svg-icons";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function FundForm({products, min, max=null}) {
  // {...selectedProductid !== '' && selectedCurrency !== '' && invAmount > 0 ? '' : 'disabled'}
  const[selectedNewProductid, setNewSelectedProductid] =  React.useState('');
  const[selectedCurrency, setSelectedCurrency] =  React.useState('');
  const[invAmount, setInvAmount] =  React.useState(0);
  const [displayModal, setDisplayModal] = React.useState(false);
  const[userid, setUserid] =  React.useState('');

  const [modalOTP, setModalOTP] = React.useState({otp: '', tcAccept: false});
    
  const [showProductPopupModal, setShowProductPopupModal] = React.useState(false);

    
  const [getShowSpinner, setShowSpinner] = React.useState(false);

  const{auth,user} = React.useContext(UserContext)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    border: '2px solid #00c4f4',
  };

  React.useEffect(()=>{
    
    if(auth && user)
    {
  
      setUserid(user.user.userid)
      // seeIfUserAlreadyHasProduct(user.user.userid)
      saveUserActivity(user.user.userid, `Viewed ${products.mainTitle} Product Page`)
    }
  },[auth])

  const saveUserActivity = async(userid, activity) => {

    var activityData = {
      userid: userid,
      activity: activity
    }

   

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`,activityData).then(async (res)=>{

    })
  }


  const productChange = (productid) => {
   
    setNewSelectedProductid(productid)
  }

  const currencyChange = (currency) => {

    setSelectedCurrency(currency)
  }

  const amountChange = (amount) => {
    setInvAmount(amount)
  };

  const onApplyButtonClick = async (button) => {
    setShowSpinner(true)

    if(selectedProductid === '')
    {
      toast.error('Please Select A Product', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if(selectedCurrency === '')
    {
      toast.error('Please Select A Currency', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if(invAmount <= 0)
    {
      toast.error('Amount Needs To Be More Than 0!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    var productCountObj = {
      userid: userid,
      productid: selectedProductid
    }

    var userHaveActiveProducts = await axios.post(process.env.REACT_APP_API_BASE_URL+"/have-active-product",productCountObj).then(async res =>{
      if(res.data.status === 200)
      {
        return true
      }else{
        return false;
      }
    });
       
    if(parseFloat(invAmount) < min[selectedCurrency])
    { 
          
      if(!userHaveActiveProducts || parseFloat(invAmount) < parseFloat(products.reContribute[selectedCurrency]))
      {
        toast.error('Amount Less Than Minimum Recontribution Amount!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setShowSpinner(false)
        return false;
      }

    }
        
    if(max && parseFloat(invAmount) > max[selectedCurrency])
    { 
      toast.error('Amount More Than Max Contribution Amount!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setShowSpinner(false)
      return false;

    }

    var balanceObject = {
      userid: userid,
      currency: selectedCurrency
    }
      
    var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{ 
      
      if(parseFloat(res.data.total) < invAmount)
      {
        toast.error('Insufficient Balance!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowSpinner(false)

        return false;
      }else{
        return true
      }

      return true;
       
    })

    if(!checkBalance)
    return false;

    setDisplayModal(true)
          
    await sendOTP();
  }

  const sendOTP = async () => {

    var otpObj = {
      userid: userid
    }

    await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
        
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowSpinner(false)

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowSpinner(false)
      }
      
    })

  }

  const closeModal = (event, reason) => {
    if (reason && reason == "backdropClick") 
    return;
    setDisplayModal(false)
  }

  const handleAccept = async () => {
    
    setShowSpinner(true)
    if(modalOTP.otp === '')
    {
      toast.error("OTP can't be empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if(modalOTP.tcAccept != true)
    {
      toast.error("Please accept the terms and conditions", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    var otpObj = {
      userid: userid,
      otp: modalOTP['otp']
    }
          
    const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                  
      if(res.data.status !== 200)
      {

        toast.error("Incorrect OTP, We Have Sent A New OPT!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        await sendOTP();

        return false;
      }else{
        return true;
      }
    });

    if(!checkOtp)
    return false;

    var createProductSae = await productSale()

    setDisplayModal(false)
    modalOTP['otp'] = '';
    modalOTP["tcAccept"] = false;
      
  }

  const productSale = async() =>{
    const investObject = {
      userid: userid,
      amount: invAmount,
      productid: selectedProductid,
      currency: selectedCurrency,
      productMonths: selectedProductDuration
    }
      
    await axios.post(process.env.REACT_APP_API_BASE_URL+"/create-productsale",investObject).then(async res =>{

      if(res.status === 200)
      {
        toast.success('Product Sale Created!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {window.location.reload(); }, 2000);

                    
      }else{
        toast.error('Unable to create Product Sale!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      
    });
  }

  const closeDepositModal = (event, reason) => {
    if (reason && reason == "backdropClick") 
    return;
  }

  const naigateToDashboard = async() => {
    window.location.href="/dashboard"
  }

  const [selectedProductDuration, setSelectedProductDuration] = React.useState('');
  const [selectedProductid, setSelectedProductid] = React.useState('');
  const productDuration = async(duration) => {
    setSelectedProductDuration(duration)
     
    setSelectedProductid(products.duration[duration], duration)
  }


  return (

    <div className="container-md mx-auto px-4">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {displayModal? 
        <ProductConfirmModal displayModal={displayModal} duration={selectedProductDuration} fixedStart={products.fixedStart} closeModal={closeModal} showSpinner={getShowSpinner} modalOTP={modalOTP} handleAccept={handleAccept} productid={selectedProductid} />
        :      
          <Modal
            open={showProductPopupModal}
            onClose={closeDepositModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"

            className="form-no-background"
          >
            <Box sx={{
              ...style, width: 400,
              background:
              "	rgba(5,0,5,0.8)"
            }}
            >
              <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>Notice</h2>


          <p id="parent-modal-description" className=" mb-4">
            Please contact info@c1wealth.co.za for any new additional contracts.
          </p>

        
          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid rgb(250, 10, 10)",
            color: "#fff"}} className='c1-primary-btn' onClick={naigateToDashboard}>Back To Dashboard</Button> 
               
   
        </Box>
      </Modal>}


        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 primary-border p-4">
          <div className="container-md px-4 primary-br-2">

            <div className="text-center mb-4">
              <h1 className='underline text-white-800 text-lg'>{products.mainTitle}</h1>
            </div>

            <div className="">
              <div dangerouslySetInnerHTML={{ __html: products.description }} />
            </div>
          </div>

          <div className="container-md px-4 pl-6">
            <div className="w-full">
                  
              <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <h1 className="title text-white text-center mb-2">{products.mainTitle}</h1>
                  <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                    Product
                  </label>
                  <select value={selectedNewProductid} onChange={(e) => productChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                    <option value="">Select Product</option>
                    {/* {products.map(product => ( */}
                    <option value={'1'}>{products.title}</option>
                    {/* ))} */}
                                
                  </select>
                </div>

                <div className="mb-4">
                  {/* <h1 className="title text-white text-center mb-2">{products.mainTitle}</h1> */}
                  <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                    Duration
                  </label>
                  <select value={selectedProductDuration} onChange={(e) => productDuration(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                    <option value="">Select Duration</option>
                                
                      {Object.keys(products.duration).map(key => 
                        // <option value={products}>{tifs[key]}</option>
                        <option key={key} value={key}>{key} months</option>
                      )
                        // Object.keys(products).map(function(duration) {
                        //     <option key={duration} value={products.duration[duration]}>{duration} months</option>
                        // })
                      } 
                                
                  </select>
                </div>

                <div className="mb-6">
                  <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                    Currency 
                  </label>{selectedProductid === '' ? <span className="text-red-400 text-sm">Please select a product first</span> : ''}
                  <select onChange={(e) => currencyChange(e.target.value)} value={selectedCurrency} {...selectedProductid === '' ? 'disabled' : ''} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline">
                    <option value="">Select Currency</option>
                      {products.hasOwnProperty('currencies') ?
                        Object.keys(products.currencies).map(key => 
                      
                          <option key={key} value={products.currencies[key]}>{products.currencies[key] === 1 ? 'USDT' : products.currencies[key] === 2 ? 'ZAR' : products.currencies[key] === 5 ? 'Loan USDT' : 'BTC'}</option>
                        )
                        :
                        <option key={'USDT'} value={'USDT'}>{'USDT'}</option>
                      }
                      {/* {selectedProductid !== '' ?
                        productCurrencies[selectedProductid].map(currency => (
                        <option key={'USDT'} value={'USDT'}>{'USDT'}</option>
                        ))
                        : ''
                      } */}
                  </select>
                       
                </div>

                <div className="mb-6">
                  <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                    Contribution Amount 
                  </label>
                  <input type="number" value={invAmount} onChange={(e) => amountChange(e.target.value)} min="0" step="1" className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
                </div>

                <div className="flex items-center justify-between">
                  <button  onClick={(e) => onApplyButtonClick(e.target.value)} {...invAmount > 0 ? 'disabled' : ''}  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Apply
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
 
    </div>
  )
}

export default FundForm