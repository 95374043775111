import React, { useState, useEffect, useRef } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Dropzone from "react-dropzone"

import CountryDropdown from 'country-dropdown-with-flags-for-react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faQuestionCircle, faPlus, faHistory, faUser } from "@fortawesome/free-solid-svg-icons";

import {
    Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    InputGroup,
    ModalBody

} from "reactstrap";

import { Link } from "react-router-dom"

import { QrReader } from 'react-qr-reader';
import { QRCode } from 'react-qrcode';


import { Buffer } from 'buffer';

import { saveUserActivity } from '../../components/Tracker/Tracker'
import LoadingText from "../../components/Loading/LoadingText";
import TourGuide from "../../components/Modals/Tours";
import PopoverTour from "../../components/Modals/Tours/PopoverTour";


import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { CiFileOn } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { CiWallet } from "react-icons/ci";
import KYCSettings from "../../components/Settings/KYCSettings";
import UploadDocumentsComponent from "../../components/Settings/UploadDocumentsComponent";




function Settings() {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const { auth, user } = React.useContext(UserContext)
    const [userid, setUserid] = React.useState('');
    const [userType, setUserType] = React.useState('0');
    const [countryCurrency, setCountryCurrency] = React.useState('ZAR');

    const [userUserid, setUserUserid] = React.useState('');

    const [showUploadedID, setShowUploadedID] = React.useState(false);
    const [showUploadedID2, setShowUploadedID2] = React.useState(false);
    const [showUploadedPOA, setShowUploadedPOA] = React.useState(false);
    const [showUploadedCIPC, setShowUploadedCIPC] = React.useState(false);
    const [showNotes, setShowNotes] = React.useState(false);
    const [displayNoteDiv, setDisplayNoteDiv] = React.useState(false);

    const [displayNotes, setDisplayNotes] = React.useState('');

    const [showBankNotes, setShowBankNotes] = React.useState(false);
    const [showBankNotesDiv, setShowBankNotesDiv] = React.useState(false);

    const [displayBankNotes, setDisplayBankNotes] = React.useState('');
    const [bankStatus, setBankStatus] = React.useState('');

    const [bankUpload, setBankUpload] = React.useState('');
    const [bankAccountName, setBankAccountName] = React.useState('');
    const [bankBankName, setBankBankName] = React.useState('');
    const [bankAccountType, setBankAccountType] = React.useState('');
    const [bankAccountNumber, setBankAccountNumber] = React.useState('');
    const [branchCode, setBranchCode] = React.useState('');
    

    const [loadingPersonalData, setLoadingPersonalData] = React.useState(true);

    const [emailLoading, setEmailLoading] = React.useState(false);
    const [profileLoading, setProfileLoading] = React.useState(false);
    const [passwordLoading, setPasswordLoading] = React.useState(false);
    const [kycLoading, setKycLoading] = React.useState(false);
    const [bankConfirmationLoading, setBankConfirmationLoading] = React.useState(false);

    const [displayFundManager, setDisplayFundManager] = React.useState(false);
    const [tourComplete, setTourComplete] = React.useState(false);

    const queryParams = new URLSearchParams(window.location.search);

    // ========================================Start of KYC Compliance=================================

    // Add new KYC details Company
    const [showUploadedCM2, setShowUploadedCM2] = React.useState(false);
    const [showUploadedCM22, setShowUploadedCM22] = React.useState(false);
    const [showUploadedCM9, setShowUploadedCM9] = React.useState(false);
    const [showUploadedCM29, setShowUploadedCM29] = React.useState(false);
    const [showUploadedBusinessNameDocumentation, setShowUploadedBusinessNameDocumentation] = React.useState(false);
    const [showUploadedProofOfBusinessResidence, setShowUploadedProofOfBusinessResidence] = React.useState(false);
    const [showUploadedTaxRegistrations, setShowUploadedTaxRegistrations] = React.useState(false);
    const [showUploadedResoluton, setShowUploadedResoluton] = React.useState(false);
    const [showUploadedBankStatement, setShowUploadedBankStatement] = React.useState(false);

    const [showUploadedCompanyCell, setShowUploadedCompanyCell] = React.useState(false);
    const [showUploadedCompanyEmail, setShowUploadedCompanyEmail] = React.useState(false);
   

    // Uploads
    const [documentUploadedCM2, setDocumentUploadedCM2] = React.useState([]);
    const [documentUploadedCM22, setDocumentUploadedCM22] = React.useState([]);
    const [documentUploadedCM9, setDocumentUploadedCM9] = React.useState([]);
    const [documentUploadedCM29, setDocumentUploadedCM29] = React.useState([]);
    const [documentUploadedBusinessNameDocumentation, setSDocumentUploadedBusinessNameDocumentation] = React.useState([]);
    const [documentUploadedProofOfBusinessResidence, setDocumentUploadedProofOfBusinessResidence] = React.useState([]);
    const [documentUploadedTaxRegistrations, setDocumentUploadedTaxRegistrations] = React.useState([]);
    const [documentUploadedResoluton, setDocumentUploadedResoluton] = React.useState([]);
    const [documentUploadedBankStatement, setDocumentUploadedBankStatement] = React.useState([]);

    const [documentUploadedCompanyCell, setDocumentUploadedCompanyCell] = React.useState([]);
    const [documentUploadedCompanyEmail, setDocumentUploadedCompanyEmail] = React.useState([]);


    // Functions

    function handleAcceptedCM2Files(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedCM2(files)
    }

    function handleAcceptedCM22Files(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedCM22(files)
    }

    function handleAcceptedCM9Files(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedCM9(files)
    }

    function handleAcceptedCM29Files(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedCM29(files)
    }

    function handleAcceptedBusinessNameDocumentationFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setSDocumentUploadedBusinessNameDocumentation(files)
    }


    function handleAcceptedProofOfBusinessResidenceFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedProofOfBusinessResidence(files)
    }


    function handleAcceptedTaxRegistrationsFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedTaxRegistrations(files)
    }


    function handleAcceptedResolutonFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedResoluton(files)
    }


    function handleAcceptedBankStatementFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedBankStatement(files)
    }


    // function handleAcceptedSourceOfFundsFiles(files) {

    //     files.map(file =>
    //         Object.assign(file, {
    //             preview: URL.createObjectURL(file),
    //             formattedSize: formatBytes(file.size)
    //         })
    //     )

    //     setDocumentUploadedSourceOfFunds(files)
    // }


    function handleAcceptedDirectorUploadsFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedDirectorUploads(files)
    }


    // Add new KYC details User
    const [showUploadedTAXNumber, setShowUploadedTAXNumber] = React.useState(false);
    const [showUploadedTAXDocument, setShowUploadedTAXDocument] = React.useState(false);

    // Uploads
    const [documentUploadedTAXNumber, setDocumentUploadedTAXNumber] = React.useState('');
    const [documentUploadedTAXDocument, setDocumentUploadedTAXDocument] = React.useState([]);

    // Functions
    function handleAcceptedTAXUploadsFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setDocumentUploadedTAXDocument(files)
    }
 

    // Add new KYC details Company/User
    const [showUploadedDirectorUploads, setShowUploadedDirectorUploads] = React.useState(false);
    const [showUploadedSourceOfFunds, setShowUploadedSourceOfFunds] = React.useState(false);

    // Uploads
    const [documentUploadedDirectorUploads, setDocumentUploadedDirectorUploads] = React.useState([]);
    const [documentUploadedSourceOfFunds, setDocumentUploadedSourceOfFunds] = React.useState('');

    // ========================================End of KYC Compliance=================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #00c4f4',
    };
    React.useEffect(() => {

        if (auth && user) {
            getUserDetails(user.user.userid)

            if (user.user.fica_status === 'N' && !tourComplete) {

                var tourObj = [

                    {
                        element: '.kyc-status-pending',
                        popover:
                        {
                            title: 'KYC Required', description: 'Kindly select the "KYC" menu item and complete all necessary fields.'
                        }
                    },

                ]

                setTourSteps(tourObj)

                setTourComplete(true)
            }
            setUserid(user.user.userid)
            setUserUserid(user.user.userid)
            setUserType(user.user.usertype)
            saveUserActivity(user.user.userid, "Viewed Settings Page")

            if (user.user.company !== '263' && user.user.company !== 263) {
                setDisplayFundManager(true)
            }

            //   handleGenerateSecret()
            getAuthQR()
            if (user.user.authid) {
                setAuthAlreadySet(true)
            }

        }

    }, [auth])

    const saveUserActivity = async (userid, activity) => {

        var activityData = {
            userid: userid,
            activity: activity
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`, activityData).then(async (res) => {

        })
    }

    const getUserDetails = async (userid) => {
        var userObj = {
            userid: userid
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`, userObj).then(async (res) => {
            if (res.data.status == 200) {

                var mainString = res.data.info.country
                if (mainString) {
                    if (mainString && mainString.includes("Namibia")) {
                        setCountryCurrency('NAD')
                    }
                }


                setName(res.data.user.name);
                setLastName(res.data.user.lastname);
                setCell(res.data.user.cell);
                setEmail(res.data.user.email);
                setOTP(res.data.user.otp_type)
                setFundManager(res.data.info.fund_manager)

                setIDNumber(res.data.info.id_num);
                setAddress(res.data.info.address);
                setSourceIncome(res.data.info.sourceFunds);
                if (res.data.info.dob !== '') {
                    setDOB(new Date(res.data.info.dob))
                    setDOBSave(res.data.info.dob)
                }


                setCountry(res.data.info.country)
                setAutoRenewBalance(res.data.user.autoRenewBalance === 'true')

                setBankStatus(res.data.info.bank_status)

                setBankUpload(res.data.info.bank_upload)

                setBankAccountName(res.data.info.bank_account_name)
                setBankBankName(res.data.info.bank_bank_name)
                setBankAccountType(res.data.info.bank_account_type)
                setBankAccountNumber(res.data.info.bank_account_number)
                if(res.data.info.bank_branch_code)
                    setBranchCode(res.data.info.bank_branch_code)

                if (res.data.info.bank_status == 'Declined') {
                    setShowBankNotes(true)
                    setShowBankNotesDiv(true)
                    setDisplayBankNotes(res.data.info.bank_notes)
                }

                if (res.data.user.fica_status === 'A' || res.data.user.fica_status === 'P') {
                    setFicaCompleted(true)
                } else {

                    if (!res.data.info.idupload && res.data.info.notes) {
                        setShowNotes(true)
                        setDisplayNoteDiv(true)

                    }

                    if (!res.data.info.poaupload && res.data.info.notes) {
                        setShowNotes(true)
                        setDisplayNoteDiv(true)

                    }

                    // if(res.data.info.cipcupload )
                    // {
                    //     setShowNotes(true)
                    //     setDisplayNoteDiv(true)
                    // }
                }
                setDisplayNotes(res.data.info.notes)
                if (res.data.info.idupload) {
                    setShowUploadedID(true)
                }

                if (res.data.info.idupload2) {
                    setShowUploadedID2(true)
                }

                if (res.data.info.poaupload) {
                    setShowUploadedPOA(true)
                }

                if (res.data.info.cipcupload) {
                    setShowUploadedCIPC(true)
                }


                if (res.data.info.cm2_upload) {
                    setShowUploadedCM2(true)
                }

                if (res.data.info.cm22_upload) {
                    setShowUploadedCM22(true)
                }


                if (res.data.info.cm9_cor_upload) {
                    setShowUploadedCM9(true)
                }


                if (res.data.info.cm29_upload) {
                    setShowUploadedCM29(true)
                }

                if (res.data.info.business_name_documentation_upload) {
                    setShowUploadedBusinessNameDocumentation(true)
                }

                if (res.data.info.business_residence_upload) {
                    setShowUploadedProofOfBusinessResidence(true)
                }

                

                if (res.data.info.tax_registrations_upload) {
                    setShowUploadedTaxRegistrations(true)
                }

                if (res.data.info.resolution_upload) {
                    setShowUploadedResoluton(true)
                }

                if (res.data.info.bank_statement_upload) {
                    setShowUploadedBankStatement(true)
                }


                if (res.data.info.tax_number) {
                    setShowUploadedTAXNumber(true)
                    setDocumentUploadedTAXNumber(res.data.info.tax_number)
                }

                
                if (res.data.info.tax_upload) {
                    setShowUploadedTAXDocument(true)
                }


                if (res.data.info.user_uploads) {
                    setShowUploadedDirectorUploads(true)
                }

                
                if (res.data.info.source_of_funds_upload) {
                    setShowUploadedSourceOfFunds(true)
                    setDocumentUploadedSourceOfFunds(res.data.info.source_of_funds_upload)
                }

                

                setLoadingPersonalData(false)
            }
        })

        return true;
    }

    const [name, setName] = React.useState('');
    const [lastname, setLastName] = React.useState('');
    const [cell, setCell] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [otp, setOTP] = React.useState('email');

    // KYC Fields
    const [idnumber, setIDNumber] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [sourceIcone, setSourceIncome] = React.useState('');
    const [idupload, setidupload] = React.useState('');
    const [poaupload, setPOAUpload] = React.useState('');
    const [dob, setDOB] = React.useState('');
    const [dobSave, setDOBSave] = React.useState('');

    const [cipc, setCipc] = React.useState('');


    const [ficaCompleted, setFicaCompleted] = React.useState(false);

    const [autoRenew, setAutoRenew] = React.useState(true);
    const [autoRenewBalance, setAutoRenewBalance] = React.useState(true);

    const [selectedOption, setSelectedOption] = React.useState('email');



    const [country, setCountry] = React.useState('South Africa');

    // Tourguide
    const [tourSteps, setTourSteps] = React.useState([]);
    const [allowTourClose, setAllowTourClose] = React.useState(false);

    const onIDChange = (id) => {
        setIDNumber(id)
    }

    const onAddressChange = (address) => {
        setAddress(address)
    }

    const onCourceIncomeChange = (income) => {
        setSourceIncome(income)
    }

    const onIDUpload = (id) => {
        setidupload(id)
    }

    const onPOAUpload = (upload) => {
        setPOAUpload(upload)
    }

    const onNameChange = (name) => {
        setName(name)
    }

    const onLastNameChange = (lastname) => {
        setLastName(lastname)
    }

    const handleRadioButtonChange = (value) => {
        setOTP(value)
    }

    const handleOnAutoRenew = (value) => {

        var isTrueSet = (value === 'true');
        if (!isTrueSet)
            setAutoRenewBalance(isTrueSet)
        setAutoRenew(isTrueSet)
    }

    const handleWalletBalanceAuto = (value) => {
        var isTrueSet = (value === 'true');

        setAutoRenewBalance(isTrueSet)
    }

    const onEmailChange = (email) => {
        setEmail(email)
    }

    const onCellChange = (cell) => {
        setCell(cell)
    }

    const onDOBChange = (dob) => {

        var dateObj = new Date(dob);

        var month = dateObj.getUTCMonth() + 1; //months from 1-12

        var day = dateObj.getUTCDate();


        var year = dateObj.getUTCFullYear();

        if (month < 10) {
            month = '0' + month.toString();
        }

        if (day < 10) {
            day = '0' + day.toString();
        }

        var newdate = year + "-" + month + "-" + day;


        setDOBSave(newdate)
        setDOB(new Date(newdate))
    }

    const onSettingsSave = async (settings) => {

        setProfileLoading(true)
        var userObj = {
            userid: userid,
            name: name,
            lastname: lastname,
            // email: email,
            cell: cell,
            otp: otp,
            autoRenew: autoRenew,
            autoRenewBalance: autoRenewBalance
        }

        var userUpdate = await axios.post(process.env.REACT_APP_API_BASE_URL + "/update-user-settings", userObj).then(async res => {

            if (res.data.status == 200) {
                toast.success('Updated User Details!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setProfileLoading(false)
            } else {
                toast.error('Unable to Update User Details', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setProfileLoading(false)
            }
        })

        await saveUserActivity(userid, "Saved Profile Settings")

    }

    const onCIPCChange = (cipc) => {
        setCipc(cipc)
    }

    const uploadedCheck = async(document, documentName, formD, required=true) => {
        if (typeof document[0] !== 'undefined' && document.length !== 0) {
            return true
        } else {
            if(required)
            {
                toast.error(`Require ${documentName} Document!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
            }

            return false
        }
    }

    const onKYCSave = async (settings) => {

        setKycLoading(true)
        if (!country || country == '') {
            toast.error('Please select a country!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setKycLoading(false)
            return false;
        }

        if (!sourceIcone || sourceIcone == '') {
            toast.error('Please select Source Of Income!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setKycLoading(false)
            return false;
        }



        if (!dobSave || dobSave == '') {
            toast.error('Please select Date of Birth!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setKycLoading(false)
            return false;
        }

        if (!idnumber || idnumber == '') {
            toast.error('Please enter ID Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setKycLoading(false)
            return false;
        }

        if (!address || address == '') {
            toast.error('Please enter Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setKycLoading(false)
            return false;
        }

        // if(typeof idupload[0] === 'undefined' || userficaupload.length === 0)
        // {
        //     toast.error('Please Upload ID Document', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //         });
        // }

        // if(typeof poaupload[0] === 'undefined' || poaupload.length === 0)
        // {
        //     toast.error('Please Upload Proof Of Address', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //         });
        // }

        // setKycLoading(false)
        // return;
        // const [useridUpload, setuseridUpload] = useState([])
        // const [userpoaupload, setuserpoaupload] = useState([])
        // const [userficaupload, setuserficaupload] = useState([])

        var formD = new FormData();
        formD.append('userid', userid)
        formD.append('country', country)
        formD.append('sourceFunds', sourceIcone)
        formD.append('dob', dobSave)
        formD.append('idNumber', idnumber)
        formD.append('cell_no', cell)

        // return;
        formD.append('address', address)
        formD.append("idupload", useridUpload[0])
        formD.append("idupload2", useridUpload2[0])
        formD.append("poaupload", userpoaupload[0])

        if (userType == '1' && !showUploadedCIPC || userType == 1 && !showUploadedCIPC) {

            formD.append("cipc", cipc)
            if (typeof userficaupload[0] !== 'undefined' && userficaupload.length !== 0) {
                formD.append("cipcupload", userficaupload[0])

            } else {

                toast.error('Require CIPC Document!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
                return false
            }

            if (cipc == '') {
                toast.error('Please enter CIPC Nr', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
                return false;
            }


        }
        if (userType == '1'  || userType == 1)
        {


            const required_cm2_upload = false;
            let formCheck = await uploadedCheck(documentUploadedCM2, "cm2_upload", formD, required_cm2_upload)

            if(formCheck)
            {
                // Append multiple files
                documentUploadedCM2.forEach(file => {
                    formD.append('cm2_upload', file);
                });
            }


            const required_cm22_upload = false;
            formCheck = await uploadedCheck(documentUploadedCM22, "cm22_upload", formD, required_cm22_upload)

            if(formCheck)
            {
            // Append multiple files
            documentUploadedCM22.forEach(file => {
                formD.append('cm22_upload', file);
            });
        }

        const required_cm29_upload = false;
            formCheck = await uploadedCheck(documentUploadedCM29, "cm29_upload", formD, required_cm29_upload)

            if(formCheck)
            {
                // Append multiple files
                documentUploadedCM29.forEach(file => {
                    formD.append('cm29_upload', file);
                });
            }

            const required_cm9_upload = false;
            formCheck = await uploadedCheck(documentUploadedCM9, "cm9_cor_upload", formD, required_cm9_upload)

                if(formCheck)
                {
            // Append multiple files
            documentUploadedCM29.forEach(file => {
                formD.append('cm9_cor_upload', file);
            });
        }

        if(!showUploadedProofOfBusinessResidence)
        {
            formCheck = await uploadedCheck(documentUploadedProofOfBusinessResidence, "business_residence_upload", formD)

            if(!formCheck)
                return false;

            // Append multiple files
            documentUploadedProofOfBusinessResidence.forEach(file => {
                formD.append('business_residence_upload', file);
            });
        }



            const required_business_name_documentation_upload = false;
            formCheck = await uploadedCheck(documentUploadedBusinessNameDocumentation, "business_name_documentation_upload	", formD, required_business_name_documentation_upload)

            // if(!formCheck)
            //     return false;
            if(formCheck)
            {
                // Append multiple files
                documentUploadedBusinessNameDocumentation.forEach(file => {
                    formD.append('business_name_documentation_upload', file);
                });

            }


            const required_registrations_upload = false;
            formCheck = await uploadedCheck(documentUploadedTaxRegistrations, "tax_registrations_upload	", formD, required_registrations_upload)

            if(formCheck)
            {
                // Append multiple files
                documentUploadedTaxRegistrations.forEach(file => {
                    formD.append('tax_registrations_upload', file);
                });

            }

            if(!showUploadedResoluton)
            {
                formCheck = await uploadedCheck(documentUploadedResoluton, "resolution_upload", formD)

                if(!formCheck)
                    return false;
    
                // Append multiple files
                documentUploadedResoluton.forEach(file => {
                    formD.append('resolution_upload', file);
                });
            }


            
            if(!showUploadedBankStatement)
            {
                formCheck = await uploadedCheck(documentUploadedBankStatement, "bank_statement_upload", formD)

                if(!formCheck)
                    return false;

                // Append multiple files
                documentUploadedBankStatement.forEach(file => {
                    formD.append('bank_statement_upload', file);
                });
            }

            // formCheck = await uploadedCheck(documentUploadedSourceOfFunds, "source_of_funds_upload", formD)

            // if(!formCheck)
            //     return false;

            // // Append multiple files
            // documentUploadedSourceOfFunds.forEach(file => {
            //     formD.append('source_of_funds_upload', file);
            // });

            if (documentUploadedSourceOfFunds && documentUploadedSourceOfFunds !== '') {
                formD.append('source_of_funds_upload', documentUploadedSourceOfFunds)
            }else{
                toast.error('Please upload source of funds!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
                return false;
            }

            if(!showUploadedDirectorUploads)
            {
                formCheck = await uploadedCheck(documentUploadedDirectorUploads, "user_uploads", formD)

                if(!formCheck)
                    return false;
    
                // Append multiple files
                documentUploadedDirectorUploads.forEach(file => {
                    formD.append('user_uploads', file);
                });
            }


            
            
        }

        if(userType == '0')
        {

            const required_tax_document_upload = false;
            let formCheck = await uploadedCheck(documentUploadedTAXDocument, "tax_upload	", formD, required_tax_document_upload)

            if(formCheck)
            {
                // Append multiple files
                documentUploadedTAXDocument.forEach(file => {
                    formD.append('tax_upload', file);
                });

            }

            // formCheck = await uploadedCheck(documentUploadedSourceOfFunds, "source_of_funds_upload", formD)

            // if(!formCheck)
            //     return false;

            // // Append multiple files
            // documentUploadedSourceOfFunds.forEach(file => {
            //     formD.append('source_of_funds_upload', file);
            // });

            if (documentUploadedSourceOfFunds && documentUploadedSourceOfFunds !== '') {
                formD.append('source_of_funds_upload', documentUploadedSourceOfFunds)
            }else{
                toast.error('Please upload source of funds!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
                return false;
            }

            if(!showUploadedDirectorUploads)
            {
                formCheck = await uploadedCheck(documentUploadedDirectorUploads, "user_uploads", formD)

                if(!formCheck)
                    return false;
    
                // Append multiple files
                documentUploadedDirectorUploads.forEach(file => {
                    formD.append('user_uploads', file);
                });
            }

            if (documentUploadedTAXNumber && documentUploadedTAXNumber !== '') {
                formD.append('tax_number', documentUploadedTAXNumber)
            }

            
        }



        await axios.post(process.env.REACT_APP_BASE + "api/update-ficadata", formD).then(async res => {

            if (res.data.status == 200) {
                // toastr.options.onHidden = function() { window.location.href="/dashboard" } 
                //   toastr.success(res.data.message) 

                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                const getUserToken = queryParams.get('complete');
                setKycLoading(false)
                if (getUserToken !== '' && getUserToken !== null && getUserToken === true) {
                    window.location.href = "/deposit"
                } else {
                    window.location.href = "/dashboard"
                }



                //   setKYCStep(kycStep + 1)
            }
            else {

                toast.error(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setKycLoading(false)
                return false
            }
        });

        await saveUserActivity(userid, "Saved KYC Settings")
    }


    // Uploads
    const [useridUpload, setuseridUpload] = useState([])
    const [useridUpload2, setuseridUpload2] = useState([])
    const [userpoaupload, setuserpoaupload] = useState([])
    const [userficaupload, setuserficaupload] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFiles2, setselectedFiles2] = useState([])

    const [bankConfirmationUpload, setBankConfirmationUpload] = useState([])

    function handleAcceptedFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setselectedFiles(files)
        setuseridUpload(files)

    }

    function handleAcceptedFiles2(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setselectedFiles2(files)
        setuseridUpload2(files)
    }

    function handleAcceptedPOAFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setuserpoaupload(files)
    }

    function handleAcceptedFicaFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setuserficaupload(files)
    }


    function handleBankConfirmFiles(files) {

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )

        setBankConfirmationUpload(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    //   Passwords

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')


    const [selectedMenuItem, setSelectedMenuItem] = useState(1)


    const [modalOTP, setModalOTP] = useState('')

    function onCurrentPasswordChange(password) {
        setCurrentPassword(password)
    }

    function onNewPasswordChange(password) {
        setNewPassword(password)
    }

    function onConfirmPasswordChange(password) {
        setConfirmPassword(password)
    }

    const onPasswordSave = async () => {
        setPasswordLoading(true)
        var userid = userUserid;
        var newPasswordSave = newPassword;
        var currentPasswordSave = currentPassword;
        var confirmPasswordSave = confirmPassword;

        if (confirmPasswordSave !== newPasswordSave) {
            setPasswordLoading(false)
            return toast.error("Passwords Don't Match", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

        var updatePasswordObj = {
            userid: userid,
            newPassword: newPasswordSave,
            currentPassword: currentPasswordSave
        }



        await axios.post(process.env.REACT_APP_BASE + "api/update-password", updatePasswordObj).then(async res => {

            if (res.data.status == 200) {
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
                toast.success("Updated Password Successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setPasswordLoading(false)
            }
            else
                toast.error("Unable To Update Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            setPasswordLoading(false)
        });

        await saveUserActivity(userid, "Saved Password Settings")
    }

    const onEmailSave = async () => {
        setModalDisplay(true)
        await sendOTP()
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userUserid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp", otpObj).then((res) => {

            if (res.data.status == 200) {

                toast.success('OTP Pin Successfully Sent!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

            } else {

                toast.error('Unable To send OTP!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

            }

        })

    }

    const updateEmail = async () => {
        var userObj = {
            userid: userUserid,
            email: email,
        }

        var userUpdate = await axios.post(process.env.REACT_APP_API_BASE_URL + "/update-user-email", userObj).then(async res => {

            if (res.data.status == 200) {
                toast.success('Updated User Email!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setEmailLoading(false)
            } else {
                toast.error('Unable to Update User Email', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setEmailLoading(false)
            }
        })

        await saveUserActivity(userid, "Saved Email Settings")
    }

    const [modalDisplay, setModalDisplay] = useState(false)

    const handleAccept = async () => {
        setModalDisplay(false)
        setEmailLoading(true)

        var otpObj = {
            userid: userUserid,
            otp: modalOTP
        }

        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

            if (res.data.status !== 200) {

                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setModalOTP('')
                setModalDisplay(true)
                setEmailLoading(false)
                await sendOTP();

                return false;
            } else {
                setModalOTP('');
                return true;
            }
        });

        if (!checkOtp)
            return false;

        if (checkOtp) {
            await updateEmail()
        }
    }

    const closeModal = (event, reason) => {

        if (reason && reason == "backdropClick")
            return;
        setModalDisplay(false)
    }

    const onModalOTPChange = async (otp) => {
        setModalOTP(otp)
    }


    // =================== 2FA CODE ================================
    const [secret, setSecret] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [code, setCode] = useState('');

    const handleScan = async (data) => {
        if (data) {
            setSecret(data);
        }
    };

    const handleError = async (err) => {
        console.error(err);
    };

    const handleGenerateSecret = async () => {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/generate-user-2fa`, {}).then(async (res) => {
            // var jsonResponse = res.json()

            var data = res.data
            setSecret(data.secret);

            setQrCodeUrl(data.qrCodeUrl);
        })

    };


    const [qrurl, setqrurl] = React.useState('');
    const [authid, setauthid] = React.useState('');
    //   const [verifyScreenDisplay, setVerifyScreenDisplay] = React.useState(false);

    const [AuthAlreadySet, setAuthAlreadySet] = React.useState(false);
    const [newVerificationCode, setNewVerificationCode] = React.useState('');
    const [verifyScreenDisplay, setVerifyScreenDisplay] = React.useState(false);

    const [fundManager, setFundManager] = React.useState('false');

    const [numAttemps, setNumAttemps] = React.useState(3);

    function getAuthQR() {

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/generate-auth`).then(async res => {

            if (res.data.status == 200) {

                // QRCode.toDataURL(res.data.tempUrl, function (err, data_url) {

                setqrurl(res.data.tempUrl)
                setauthid(res.data.id)
                // });

            }

        });


    }

    function setVerifyScreen() {
        setVerifyScreenDisplay(true);
    }

    const VerifyNow = async () => {

        // if(numAttemps == 0)
        // {
        //     toast.error(`Too many attempts failed. Please try again later!`, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //     });

        //     setTimeout(() => {window.location.reload(); }, 2000);
        // }

        const verifyObject = {
            "userid": authid,
            "token": newVerificationCode
        }


        await axios.post(process.env.REACT_APP_BASE + "api/verify-auth", verifyObject).then(async res => {

            if (res.data.verified == true) {

                var updateUserObj = {
                    userid: user.user.userid,
                    authid: authid,
                    secret: res.data.secret.base32
                }

                // Now lets update the user authid
                await axios.post(process.env.REACT_APP_BASE + "api/update-user-auth", updateUserObj).then(async res2 => {
                    setAuthAlreadySet(true)

                    toast.success(`Successfully Verified!`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                    setTimeout(() => { window.location.reload(); }, 2000);

                });

            }
            else {
                var num = 0;

                num = numAttemps - 1;
                setNumAttemps(num)
                return toast.error(`Incorrect Verification Code!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

        });

        await saveUserActivity(userid, "Saved 2FA Settings")
    }

    // =================== END 2FA CODE ================================


    // const[bankAccountName, setBankAccountName] =  React.useState('');
    // const[bankBankName, setBankBankName] =  React.useState('');
    // const[bankAccountType, setBankAccountType] =  React.useState('');
    // const[bankAccountNumber, setBankAccountNumber] =  React.useState('');

    const onAccountNameChange = async (name) => {
        setBankAccountName(name)
    }

    const onBankNameChange = async (name) => {
        setBankBankName(name)
    }

    const onAccountTypeChange = async (name) => {
        setBankAccountType(name)
    }

    const onAccountNumberChange = async (name) => {
        setBankAccountNumber(name)
    }

    const onBranchCodeChange = async (branchCode) => {
        setBranchCode(branchCode)
    }

    const onBankConfirmationUpload = async (name) => {
        setBankConfirmationLoading(true)
        if (bankAccountName === '' || bankAccountType === '' || bankAccountType === '' || bankAccountNumber === ''|| branchCode === '' || typeof bankConfirmationUpload[0] === 'undefined') {
            setBankConfirmationLoading(false)
            return toast.error('Please fill in all banking information', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }


        var formD = new FormData();
        formD.append('userid', userid)
        formD.append('accountName', bankAccountName)
        formD.append('bankName', bankBankName)
        formD.append('accountType', bankAccountType)
        formD.append('accountNumber', bankAccountNumber)
        formD.append('branchCode', branchCode)
        
        formD.append("bankUpload", bankConfirmationUpload[0])

        // Now lets update the user authid
        await axios.post(process.env.REACT_APP_BASE + "api/update-bank-account-details", formD).then(async res => {
            if (res.data.status === 200) {
                toast.success('Bank Confirmation Updated', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => { window.location.reload(); }, 2000);

            } else {

                toast.error('Failed To Update Bank Confirmation', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setBankConfirmationLoading(false)
            }

        });

        await saveUserActivity(userid, "Saved Bank Confirmation Settings")
    }

    const bankConfirmationRef = useRef(null);
    const emailRef = useRef(null);
    const profileRef = useRef(null);
    const passwordRef = useRef(null);
    const KYCRef = useRef(null);

    const handleItemClick = async (item) => {
        setSelectedMenuItem(item)
        // if(item === 1)
        // {
        //     window.scrollTo({top: emailRef.current.offsetTop, behavior: 'smooth'})
        // }else if(item === 2)
        // {
        //     window.scrollTo({top: profileRef.current.offsetTop, behavior: 'smooth'})
        // }else if(item === 3)
        // {
        //     window.scrollTo({top: passwordRef.current.offsetTop, behavior: 'smooth'})
        // }else if(item === 4)
        // {
        //     window.scrollTo({top: KYCRef.current.offsetTop, behavior: 'smooth'})
        // }else if(item === 5)
        // {
        //     window.scrollTo({top: bankConfirmationRef.current.offsetTop, behavior: 'smooth'})
        // }

    }

    const handleFundManagerOption = async (value) => {
        setFundManager(value)
        var updateOptionObj = {
            userid: userid,
            settingValue: value,
        }

        const updateOption = await axios.post(process.env.REACT_APP_BASE + "api/update-fund-manager-option", updateOptionObj)

        toast.success('Updated Fund Manager Option!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    const [tourObject, setTourObject] = React.useState({});
    const [showPopover, setShowPopover] = useState(true);

    const getHelperFunction = async (e) => {
        e.preventDefault();
        // deposit
        // exchange
        // withdrawal

     
        var tourObj = {
            element: '.wallet-balance-for-product-renewal',
            popover: {
                title: 'Wallet Balance For Product Renewal',
                description: 'The balance available in your wallet will be utilized for the renewal of the product when tyhe expiration date is reached. The balance will also be added to product every three months. Please note that this is not applicable for any capital withdrawals.',
                side: "left",
                align: 'start',
                allowClose: true,
            }
        };

        setTourObject(tourObj);
        setShowPopover(true);

    };

    // Wallet Address Management
    const [loadingWalletAddressManagement, setWalletAddressManagement] = React.useState(false);
    const [USDTERCWalletAddress, setUSDTERCWalletAddress] = React.useState(null);
    const [USDTTRCWalletAddress, setUSDTTRCWalletAddress] = React.useState(null);
    const [BTCWalletAddress, setBTCWalletAddressWalletAddress] = React.useState(null);

    const [USDTERCWalletAddressChange, setUSDTERCWalletAddressChange] = React.useState(false);
    const [USDTTRCWalletAddressChange, setUSDTTRCWalletAddressChange] = React.useState(false);
    const [BTCWalletAddressChange, setBTCWalletAddressWalletAddressChange] = React.useState(false);

    const getUserWalletInformation = async () => {

        var USDTERCWalletInformationObj = {
            userid: userUserid,
            meta_key: 'usdt_erc_wallet_address'
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-meta`, USDTERCWalletInformationObj).then(async res => {
           
            if (res.data.status === 200) {
                setUSDTERCWalletAddressChange(true)
                setUSDTERCWalletAddress(res.data.body)
            }
        });

        var USDTTRCWalletInformationObj = {
            userid: userUserid,
            meta_key: 'usdt_trc_wallet_address'
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-meta`, USDTTRCWalletInformationObj).then(async res => {
           
            if (res.data.status === 200) {
                setUSDTTRCWalletAddressChange(true)
                setUSDTTRCWalletAddress(res.data.body)
            }
        });

        var BTCWalletInformationObj = {
            userid: userUserid,
            meta_key: 'usdt_btc_wallet_address'
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-meta`, BTCWalletInformationObj).then(async res => {
     
            if (res.data.status === 200) {
                setBTCWalletAddressWalletAddressChange(true)
                setBTCWalletAddressWalletAddress(res.data.body)
            }
        });
    }

    const onWalletAddressUpdate = async (type, walletAddress) => {
        if (type === 'erc20') {
            setUSDTERCWalletAddress(walletAddress)
        }

        if (type === 'trc20') {
            setUSDTTRCWalletAddress(walletAddress)

        }

        if (type === 'btc') {
            setBTCWalletAddressWalletAddress(walletAddress)

        }
    }

    const updateUserWalletAddress = async () => {
        if (USDTERCWalletAddress && !USDTERCWalletAddressChange) {
            var walletInfoObj = {
                userid: userUserid,
                meta_key: 'usdt_erc_wallet_address',
                meta_value: USDTERCWalletAddress
            }

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-meta`, walletInfoObj).then(async res => {
                console.log("Wallet Updated! ")
            });
        }

        if (USDTTRCWalletAddress && !USDTTRCWalletAddressChange) {
            var walletInfoObj = {
                userid: userUserid,
                meta_key: 'usdt_trc_wallet_address',
                meta_value: USDTTRCWalletAddress
            }

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-meta`, walletInfoObj).then(async res => {
                console.log("Wallet Updated! ")
            });
        }

        if (BTCWalletAddress && !BTCWalletAddressChange) {
            var walletInfoObj = {
                userid: userUserid,
                meta_key: 'usdt_btc_wallet_address',
                meta_value: BTCWalletAddress
            }

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-meta`, walletInfoObj).then(async res => {
                console.log("Wallet Updated! ")
            });
        }

        toast.success('Wallet Information Updated Successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    return (
        <div className="container mx-auto px-2 pt-8 pb-8 ">


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {/* {!tourComplete ? */}

            {/* : ''} */}
            <Modal
                open={modalDisplay}
                onClose={closeModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"

                className="form-no-background"
            >
                <Box
                    sx={{
                        ...style, width: 400,
                        background:
                            "	rgba(5,0,5,0.8)"
                    }}
                >
                    <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>OTP: </h2>

                    <input type="number" value={modalOTP} onChange={(e) => onModalOTPChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                    <Button style={{
                        background: "#040E18",
                        borderRadius: "70px",
                        border: "2px solid #00c4f4",
                        color: "#fff"
                    }} className='c1-primary-btn' onClick={handleAccept}>Confirm</Button>

                    <Button style={{
                        background: "#040E18",
                        borderRadius: "70px",
                        border: "2px solid #fa0a0a",
                        float: "right",
                        color: "#fff"
                    }} className='c1-primary-btn' onClick={closeModal}>Close</Button>

                </Box>

            </Modal>
            {displayNoteDiv ?
                <div className="c1-alert">
                    <span class="c1-closebtn" onClick={(e) => {
                        setDisplayNoteDiv(false)
                        window.scrollTo({ top: KYCRef.current.offsetTop, behavior: 'smooth' })
                    }
                    }>&times;</span>
                    <h2>KYC Application Declined</h2>

                    <p>{displayNotes}</p>
                </div>
                : ''}

            {showBankNotesDiv ?
                <div className="c1-alert mt-2">
                    <span class="c1-closebtn" onClick={(e) => {
                        setShowBankNotesDiv(false)
                        window.scrollTo({ top: bankConfirmationRef.current.offsetTop, behavior: 'smooth' })
                    }
                    }>&times;</span>
                    <h2 className="underline"><strong>Bank Confirmation Declined</strong></h2>

                    <p>{displayBankNotes}</p>
                </div>
                : ''}

            <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-2xl text-white">Settings</h1>

            <TourGuide steps={tourSteps} allowClose={allowTourClose} display={!tourComplete} onClose={() => {
                // Call your custom function or perform any actions when the "Done" button is clicked
                console.log('Tour guide is closed.');
            }}
                onFinish={() => {
                    // Call your custom function or perform any actions when all tour steps are completed
                    console.log('Tour guide is finished.');
                }} />

            <div class="menu-container">
                <div class={`menu-item ${selectedMenuItem == 1 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => { handleItemClick(1) }} > <CiMail /><span>Email</span> </div>
                <div class={`menu-item ${selectedMenuItem == 2 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => { handleItemClick(2) }}> <CiUser /><span>Profile</span> </div>
                <div class={`menu-item ${selectedMenuItem == 3 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => { handleItemClick(3) }}> <CiLock /><span>Password</span> </div>
                <div class={`menu-item kyc-status-pending ${selectedMenuItem == 4 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => { handleItemClick(4) }}> <CiFileOn /><span>KYC</span> </div>
                <div class={`menu-item ${selectedMenuItem == 5 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => { handleItemClick(5) }}> <CiBank /><span>Bank Confirmation</span> </div>
                <div class={`menu-item ${selectedMenuItem == 6 ? 'menu-item-selected' : ''}`} style={{ cursor: 'pointer' }} onClick={(e) => {
                    handleItemClick(6)
                    getUserWalletInformation()
                }}> <CiWallet /><span>Wallet Address Management</span>
                </div>
            </div>
            <div className="container-md mx-auto px-4 form-no-background">

                <form>
                    <div className="container-md px-4 p-3 authentication-registration-container">

                        {/* Email Section */}
                        {selectedMenuItem === 1 ?
                            <div>
                                <div className="mb-6 mt-6 " ref={emailRef}>
                                    <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Email</h1>
                                    <small className="text-white">Your email address is your identity on C1 Wealth and is used to log in.</small>
                                </div>
                                {loadingPersonalData || emailLoading ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Company ' : ''}Email
                                            </label>
                                            <input type="text" value={email} onChange={(e) => onEmailChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>

                                        <div className="flex items-center justify-between mb-2">
                                            <button onClick={(e) => onEmailSave(e.target.value)} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline " type="button">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                            : ''}

                        {/* <br /><hr /><br /> */}

                        {/* PROFILE SECTION */}
                        {selectedMenuItem === 2 ?
                            <div>
                                <div className="mb-6 mt-6 " ref={profileRef}>
                                    <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Profile</h1>
                                </div>
                                {loadingPersonalData || profileLoading ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Company ' : ''} Name
                                            </label>
                                            <input type="text" disabled={true} value={name} onChange={(e) => onNameChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>

                                        {userType !== '1' ?
                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    LastName
                                                </label>
                                                <input type="text" disabled={true} value={lastname} onChange={(e) => onLastNameChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>
                                            : ''}

                                        <div className="mb-6" ref={passwordRef}>
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Company ' : ''}Cell
                                            </label>
                                            <input type="text" value={cell} onChange={(e) => onCellChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>

                                        {/* Deprecated for now 2023-09-18 */}
                                        {/* <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    AutoRenew Products <small className="text-danger">(System Will automatically renew products when your contract period expires.)</small>
                                                </label>
                                                
                                                <input onClick={(e) => handleOnAutoRenew(e.target.value)} type="radio" checked={autoRenew === true}  className="btn-check" name="autorenew-radio"  value={true} id="yesauto" />
                                                <label className={'c1-primary-radio mr-8' + (autoRenew === true ? ' c1-primary-btn-active' : '')} htmlFor="yesauto">Yes</label>
                                                <input onClick={(e) => handleOnAutoRenew(e.target.value)} checked={autoRenew === false}  type="radio" className="btn-check" name="autorenew-radio" value={false} id="noauto"/>
                                                <label className={'c1-primary-radio mr-8' + (autoRenew === false ? ' c1-primary-btn-active' : '')} htmlFor="noauto">No</label>      
                                            </div> */
                                        }

                                        {/* {autoRenew === true ? */}
                                        <div className="mb-6 wallet-balance-for-product-renewal">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Add your wallet balance to your product every three months and also when the product reaches its expiration
                                                date. <small>{`(Please note that this is not applicable for Capital Withdrawals.)`}</small>
                                            </label>

                                            {/* <div className="mb-2">
                                                    <button onClick={(e) => getHelperFunction(e)} className="text-white text-sm underline hover:text-primary-color">
                                                        Need Help <FontAwesomeIcon icon={faQuestionCircle} className='ml-1' />
                                                    </button>
                                                </div> */
                                            }

                                            <div>
                                                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} type="radio" checked={autoRenewBalance === true} className="btn-check" name="autorenewbalance-radio" value={true} id="yesautobalance" />
                                                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === true ? ' c1-primary-btn-active' : '')} htmlFor="yesautobalance">Yes</label>
                                                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} checked={autoRenewBalance === false} type="radio" className="btn-check" name="autorenewbalance-radio" value={false} id="noautobalance" />
                                                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === false ? ' c1-primary-btn-active' : '')} htmlFor="noautobalance">No</label>
                                            </div>
                                        </div>
                                        {/* : ''} */}


                                        {displayFundManager ?
                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Fund Manager <small className="text-muted">(Earn proceeds of 0.5% to 1.5% on the total funds maintained in your wallet for a minimum period of 30 days.)</small>
                                                </label>

                                                <input onClick={(e) => handleFundManagerOption(e.target.value)} type="radio" checked={fundManager === 'true'} className="btn-check" name="fundmanager-radio" value={true} id="yesfundmanager" />
                                                <label className={'c1-primary-radio mr-8' + (fundManager === 'true' ? ' c1-primary-btn-active' : '')} htmlFor="yesfundmanager">Yes</label>
                                                <input onClick={(e) => handleFundManagerOption(e.target.value)} checked={fundManager === 'false'} type="radio" className="btn-check" name="fundmanager-radio" value={false} id="nofundmanager" />
                                                <label className={'c1-primary-radio mr-8' + (fundManager === 'false' ? ' c1-primary-btn-active' : '')} htmlFor="nofundmanager">No</label>
                                            </div>
                                            : ''}


                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                2FA Authentication (Google Authenticator)
                                            </label>


                                            {/* 2FA Verification */}
                                            <div>

                                                {!AuthAlreadySet ?
                                                    <div className="myaccount-info-wrapper">
                                                        <small>Step 1: Open Google Authenticator app and scan the barcode below.</small>
                                                        <div className="account-info-wrapper">
                                                            <img src={qrurl} />
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="billing-info">
                                                                    <div className="billing-back-btn">
                                                                        <div className="billing-btn">
                                                                            <a onClick={() => setVerifyScreen()} className="c1-primary-btn-sm text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mt2">Continue</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <label className={'c1-primary-radio mr-8 c1-primary-btn-active'} htmlFor="allOption">Enabled</label>
                                                }

                                                {(verifyScreenDisplay) ?
                                                    <div className="mt-2">
                                                        <small>Step 2: Enter verification code.</small>

                                                        <div className="myaccount-info-wrapper">
                                                            <div className="account-info-wrapper">

                                                                <Input
                                                                    name="newverifylink"
                                                                    style={{ maxWidth: 300 }}
                                                                    className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    placeholder="Verification Code"
                                                                    type="text"
                                                                    onChange={(e) => setNewVerificationCode(e.target.value)}
                                                                />

                                                                <div className="billing-back-btn mt-2">
                                                                    <div className="billing-btn">
                                                                        <a onClick={() => VerifyNow()} className="c1-primary-btn-sm text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mt2">Verify</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : ''
                                                }
                                            </div>

                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                OTP Option
                                            </label>

                                            <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={otp === 'email'} className="btn-check" name="vbtn-radio" value="email" id="allOption" />
                                            <label className={'c1-primary-radio mr-8' + (otp === 'email' ? ' c1-primary-btn-active' : '')} htmlFor="allOption">Email</label>
                                            <input onClick={(e) => handleRadioButtonChange(e.target.value)} checked={otp === 'sms'} type="radio" className="btn-check" name="vbtn-radio" value="sms" id="seperateOption" />
                                            <label className={'c1-primary-radio mr-8' + (otp === 'sms' ? ' c1-primary-btn-active' : '')} htmlFor="seperateOption">SMS</label>

                                        </div>

                                        <div className="flex items-center justify-between mb-2">
                                            <button onClick={(e) => onSettingsSave(e.target.value)} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            : ''}
                        {/* END PROFILE SECTION */}

                        {/* <br /><hr /><br /> */}

                        {/* Password SECTION */}
                        {selectedMenuItem === 3 ?
                            <div>
                                <h1 className="mb-6 mt-6 text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Password</h1>

                                {loadingPersonalData || passwordLoading ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Current Password
                                            </label>

                                            <input type="password" value={currentPassword} onChange={(e) => onCurrentPasswordChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                New Password
                                            </label>

                                            <input type="password" value={newPassword} onChange={(e) => onNewPasswordChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>


                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Confirm Password
                                            </label>

                                            <input type="password" value={confirmPassword} onChange={(e) => onConfirmPasswordChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>

                                        <div className="flex items-center justify-between mb-2">
                                            <button onClick={(e) => onPasswordSave(e.target.value)} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                            : ''}
                        {/* END PASSWORD SECTION */}

                        {/* <br /><hr /><br /> */}

                        {/* KYC SECTION */}
                        {selectedMenuItem === 4 ?
                            <div>
                                {/* <KYCSettings 
                                    loadingPersonalData={loadingPersonalData} 
                                    kycLoading={kycLoading} 
                                    KYCRef={KYCRef} 
                                    showNotes={showNotes}  
                                    idnumber={idnumber}
                                    ficaCompleted={ficaCompleted}
                                    onIDChange={onIDChange}
                                    userType={userType}
                                    dob={dob}
                                    onDOBChange={onDOBChange}
                                /> */}
                                <div className="mb-6 mt-6 " ref={KYCRef}>
                                    <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white" id="kycfields">KYC</h1><span style={{ color: "rgb(249 115 22)" }} className='text-orange-500 text-sm'>(Please note that these fields can't be changed once Saved and will require assistance from Admin to be changed)</span>
                                </div>
                                {loadingPersonalData || kycLoading ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        {showNotes ?
                                            <div className="mb-2">
                                                <h5 className='text-red-500 text-sm'>Admin Declined KYC Application due to the following reason:</h5>
                                                <textarea disabled className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline">{displayNotes}</textarea> <br />
                                            </div>
                                            : ''}


                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                ID Number
                                            </label>
                                            <input type="text" disabled={ficaCompleted ? true : false} value={idnumber} onChange={(e) => onIDChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Director ' : ''}Date Of Birth
                                            </label>
                                            {/* <input                  
                                                name="dob"
                                                placeholder="yyyy-mm-dd"    
                                                type="date"
                                                onChange={(e)=>setDOB(e.target.value)}
                                                value={dob} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" 
                                                /> */
                                            }

                                            <DatePicker disabled={ficaCompleted ? true : false} dateFormat="yyyy-MM-dd" value={dob} selected={dob} onChange={(date) => onDOBChange(date)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>


                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Address
                                            </label>
                                            <input type="text" disabled={ficaCompleted ? true : false} value={address} onChange={(e) => onAddressChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                        </div>


                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Source Of Income
                                            </label>
                                            <select disabled={ficaCompleted ? true : false} value={sourceIcone} onChange={(e) => onCourceIncomeChange(e.target.value)} className="authentication-bottom-border form-control shadow appearance-none w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline">
                                                <option value="">Select An Option</option>
                                                <option value="Savings">Savings</option>
                                                <option value="Pension Fund">Pension Fund</option>
                                                <option value="Sale of shares">Sale of shares</option>
                                                <option value="Sale of property">Sale of property</option>
                                                <option value="Inheritance">Inheritance</option>
                                                <option value="Dividends">Dividends</option>
                                                <option value="Trading">Trading</option>
                                                <option value="Winnings">Winnings</option>
                                                <option value="Compensation">Compensation</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                Select Country
                                            </label>
                                            <CountryDropdown disabled={ficaCompleted ? true : false} id="countryID" className='authentication-bottom-border form-control shadow appearance-none w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline' preferredCountries={['za', 'us']} value={country} handleChange={e => setCountry(e.target.value)} ></CountryDropdown>
                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Director ' : ''} ID Upload <small>(Copy in Color) </small> <text className="text-red-500">*Required</text>
                                            </label>

                                            {ficaCompleted ?
                                                <p className="text-green-500">Documents already uploaded!</p>
                                                :
                                                <div>
                                                    {showUploadedID ? <p className="text-green-500">ID Uploaded!</p> :
                                                        <div>
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {useridUpload.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            style={{ maxHeight: "150px" }}
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            alt={f.name}
                                                                                            src={f.preview}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })}
                                                            </div>
                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles(acceptedFiles)
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} type="file" name="userUpload" />
                                                                            <div className="dz-message needsclick">
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop Proof of ID here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <br />
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Director ' : ''} ID Upload 2 <small>(Copy in Color) </small> <text className="text-green-500">*Optional</text>
                                            </label>

                                            {ficaCompleted ?
                                                <p className="text-green-500">Documents already uploaded!</p>
                                                :
                                                <div>
                                                    {showUploadedID2 ? <p className="text-green-500">ID Uploaded!</p> :
                                                        <div>
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {useridUpload2.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            style={{ maxHeight: "150px" }}
                                                                                            alt={f.name}
                                                                                            src={f.preview}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })}
                                                            </div>
                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles2(acceptedFiles)
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} type="file" name="userUpload" />
                                                                            <div className="dz-message needsclick">
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop Proof of ID here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <br />
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                {userType === '1' ? 'Director ' : ''}Proof of Address Upload
                                            </label>
                                            {ficaCompleted ?
                                                <p className="text-green-500">Documents already uploaded!</p>
                                                :

                                                <div>
                                                    {showUploadedPOA ? <p className="text-green-500">Proof Of Address Uploaded!</p> :
                                                        <div>
                                                            <span className='text-red-500 text-sm'>(Not older than 3 months)</span>
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {userpoaupload.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            style={{ maxHeight: "150px" }}
                                                                                            alt={f.name}
                                                                                            src={f.preview}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })}
                                                            </div>
                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedPOAFiles(acceptedFiles)
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} type="file" name="userUpload" />
                                                                            <div className="dz-message needsclick">
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop files here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </div>

                                        {userType === '1' ?
                                            // Add all company requirements 
                                            <div>

                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CIPC Number
                                                    </label>
                                                    <input type="text" value={cipc} onChange={(e) => onCIPCChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                                </div>

                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CIPC Documents
                                                    </label>
                                                    <div>
                                                        {ficaCompleted ?
                                                            <p className="text-green-500">Documents already uploaded!</p>
                                                            :
                                                            <div>
                                                                {showUploadedCIPC ? <p className="text-green-500">CIPC Uploaded!</p> :
                                                                    <div>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">
                                                                            {userficaupload.map((f, i) => {
                                                                                return (
                                                                                    <Card
                                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        style={{ maxHeight: "150px" }}
                                                                                                        alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <Dropzone
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFicaFiles(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} type="file" name="userUpload" />
                                                                                        <div className="dz-message needsclick">
                                                                                            <div className="mb-3">
                                                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                            </div>
                                                                                            <h4>Drop files here or click to upload.</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                }

                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* CM2: Memorandum of Association */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CM2 (Cor15.1 A): Memorandum of Association <span className='text-green-500 text-sm'>*Optional</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedCM2}
                                                        alreadyUploadedText={"CM2 Uploaded!"}
                                                        uploadedDocuments={documentUploadedCM2}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedCM2Files}
                                                    />

                                                </div>

                                                {/* CM22: Notice of Registered Office */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CM22 (Cor 21): Notice of Registered Office <span className='text-green-500 text-sm'>*Optional</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedCM22}
                                                        alreadyUploadedText={"CM22 Uploaded!"}
                                                        uploadedDocuments={documentUploadedCM22}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedCM22Files}
                                                    />

                                                </div>

                                                {/* CM9/CoR: Certificate of Recognition */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CM9/CoR (Cor18.2): Certificate of Recognition <span className='text-green-500 text-sm'>*Optional</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedCM9}
                                                        alreadyUploadedText={"CM9/CoR Uploaded!"}
                                                        uploadedDocuments={documentUploadedCM9}
                                                        subText={"(If you do not have the required documents please upload your COR14.3)"}
                                                        handleAcceptedFile={handleAcceptedCM9Files}
                                                    />

                                                </div>

                                                {/* CM29: Registrar of Directors, Auditors, and Officers */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        CM29 (Cor14.3): Registrar of Directors, Auditors, and Officers <span className='text-green-500 text-sm'>*Optional</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedCM29}
                                                        alreadyUploadedText={"CM29: Registrar of Directors, Auditors, and Officers Uploaded!"}
                                                        uploadedDocuments={documentUploadedCM29}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedCM29Files}
                                                    />

                                                </div>

                                                {/* Business Name Documentation */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Business Name Documentation <span className='text-green-500 text-sm'>(Optional)</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={ficaCompleted || showUploadedBusinessNameDocumentation}
                                                        alreadyUploadedText={"Business Name Documentation Uploaded!"}
                                                        uploadedDocuments={documentUploadedBusinessNameDocumentation}
                                                        subText={"If different from the registered name"}
                                                        handleAcceptedFile={handleAcceptedBusinessNameDocumentationFiles}
                                                    />

                                                </div>

                                                {/* Proof of Business Residence */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Proof of Business Residence <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedProofOfBusinessResidence}
                                                        alreadyUploadedText={"Proof of Business Residence Uploaded!"}
                                                        uploadedDocuments={documentUploadedProofOfBusinessResidence}
                                                        subText={"Not older than 3 months"}
                                                        handleAcceptedFile={handleAcceptedProofOfBusinessResidenceFiles}
                                                    />

                                                </div>

                                                {/* Tax Registrations */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Tax Registrations <span className='text-green-500 text-sm'>(Optional)</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={ficaCompleted || showUploadedTaxRegistrations}
                                                        alreadyUploadedText={"Tax Registrations Uploaded!"}
                                                        uploadedDocuments={documentUploadedTaxRegistrations}
                                                        subText={"Proof of ordinary Tax and VAT registration"}
                                                        handleAcceptedFile={handleAcceptedTaxRegistrationsFiles}
                                                    />

                                                </div>

                                                {/* Resolution */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Resolution <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedResoluton}
                                                        alreadyUploadedText={"Resolution Uploaded!"}
                                                        uploadedDocuments={documentUploadedResoluton}
                                                        subText={"On company letterhead, signed by Directors, nominating an authorised signatory"}
                                                        handleAcceptedFile={handleAcceptedResolutonFiles}
                                                    />

                                                </div>

                                                {/* Bank Statement */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Bank Statement <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedBankStatement}
                                                        alreadyUploadedText={"Bank Statement Uploaded!"}
                                                        uploadedDocuments={documentUploadedBankStatement}
                                                        subText={"Not older than 3 months"}
                                                        handleAcceptedFile={handleAcceptedBankStatementFiles}
                                                    />

                                                </div>

                                                {/* Source of Funds */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Source of Funds <small>(Please type in a description of the exact source of funds.)</small> <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <input type="text" disabled={showUploadedSourceOfFunds} value={documentUploadedSourceOfFunds} onChange={(e) => setDocumentUploadedSourceOfFunds(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                                                    {/* <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedSourceOfFunds}
                                                        alreadyUploadedText={"Source of Funds Uploaded!"}
                                                        uploadedDocuments={documentUploadedSourceOfFunds}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedSourceOfFundsFiles}
                                                    /> */}

                                                </div>

                                                {/* For Each Director/Authorised Signatory */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        For Each Director/Authorised Signatory <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedDirectorUploads}
                                                        alreadyUploadedText={"Director/Authorised Signatory Uploaded!"}
                                                        uploadedDocuments={documentUploadedDirectorUploads}
                                                        subText={"Color ID copies, recent proof of residence, and a photo with the ID document for each Director/Authorised Signatory that have not yet been uploaded."}
                                                        handleAcceptedFile={handleAcceptedDirectorUploadsFiles}
                                                    />

                                                </div>
                                            </div>

                                            :
                                            // Add all user requirements 
                                            <div>
                                                {/* Source of Funds */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Source of Funds <small>(Please type in a description of the exact source of funds.)</small><span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    {/* <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedSourceOfFunds}
                                                        alreadyUploadedText={"Source of Funds Uploaded!"}
                                                        uploadedDocuments={documentUploadedSourceOfFunds}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedSourceOfFundsFiles}
                                                    /> */}
                                                      <input type="text" disabled={showUploadedSourceOfFunds} value={documentUploadedSourceOfFunds} onChange={(e) => setDocumentUploadedSourceOfFunds(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                                </div>

                                                {/* For Each Director/Authorised Signatory */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        Proof of Identity <span className='text-red-500 text-sm'>*Required</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedDirectorUploads}
                                                        alreadyUploadedText={"Proof of Identity Uploaded!"}
                                                        uploadedDocuments={documentUploadedDirectorUploads}
                                                        subText={"A photo holding your ID document."}
                                                        handleAcceptedFile={handleAcceptedDirectorUploadsFiles}
                                                    />

                                                </div>

                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        TAX Number <span className='text-green-500 text-sm'>(Optional)</span>
                                                    </label>
                                                    <input type="text" disabled={showUploadedTAXNumber} value={documentUploadedTAXNumber} onChange={(e) => setDocumentUploadedTAXNumber(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                                </div>

                                                
                                                {/* TAX Document */}
                                                <div className="mb-6">
                                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                        TAX Document <span className='text-green-500 text-sm'>(Optional)</span>
                                                    </label>
                                                    <UploadDocumentsComponent
                                                        alreadyUploaded={showUploadedTAXDocument}
                                                        alreadyUploadedText={"TAX Document Uploaded!"}
                                                        uploadedDocuments={documentUploadedTAXDocument}
                                                        subText={""}
                                                        handleAcceptedFile={handleAcceptedTAXUploadsFiles}
                                                    />

                                                </div>
                                            </div>
                                        }

                                        {!ficaCompleted ?
                                            <div className="flex items-center justify-between">
                                                <button onClick={(e) => onKYCSave(e.target.value)} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                    Save
                                                </button>
                                            </div>
                                            : ''}
                                    </div>
                                }
                            </div>
                            : ''}
                        {/* END KYC SECTION */}

                        {/* <br /><hr /><br /> */}

                        {/* Banking SECTION */}
                        {selectedMenuItem === 5 ?
                            <div>
                                <div className="mb-6 mt-6 " ref={bankConfirmationRef}>
                                    <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white" id="kycfields">Bank Confirmation</h1><span style={{ color: "rgb(249 115 22)" }} className='text-orange-500 text-sm'>(Applicable to all {countryCurrency} withdrawals)</span>
                                </div>
                                {loadingPersonalData || bankConfirmationLoading ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        {showBankNotes ?
                                            <div className="mb-2">
                                                <h5 className='text-red-500 text-sm'>Bank Details Declined:</h5>
                                                <textarea disabled className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline">{displayBankNotes}</textarea> <br />
                                            </div>
                                            : ''}

                                        <div>
                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Status
                                                </label>
                                                <input disabled type="text" value={bankStatus} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Account Name
                                                </label>
                                                <input disabled={bankStatus !== 'Not Started' && bankStatus !== 'Declined' ? true : false} type="text" value={bankAccountName} onChange={(e) => onAccountNameChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Account Type
                                                </label>
                                                <input disabled={bankStatus !== 'Not Started' && bankStatus !== 'Declined' ? true : false} type="text" value={bankAccountType} onChange={(e) => onAccountTypeChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Bank Name
                                                </label>
                                                <input disabled={bankStatus !== 'Not Started' && bankStatus !== 'Declined' ? true : false} type="text" value={bankBankName} onChange={(e) => onBankNameChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Account Number
                                                </label>
                                                <input disabled={bankStatus !== 'Not Started' && bankStatus !== 'Declined' ? true : false} type="text" value={bankAccountNumber} onChange={(e) => onAccountNumberChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            
                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Branch Code
                                                </label>
                                                <input disabled={bankStatus !== 'Not Started' && bankStatus !== 'Declined' ? true : false} type="text" value={branchCode} onChange={(e) => onBranchCodeChange(e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    Proof Of Bank Upload
                                                </label>

                                                {bankUpload && bankStatus !== 'Declined' ?
                                                    <p className="text-green-500">Documents already uploaded!</p>
                                                    :
                                                    <div>
                                                        <div>
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {bankConfirmationUpload.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border rounded dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            style={{ maxHeight: "150px" }}
                                                                                            alt={f.name}
                                                                                            src={f.preview}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })}
                                                            </div>
                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleBankConfirmFiles(acceptedFiles)
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} type="file" name="userUpload" />
                                                                            <div className="dz-message needsclick">
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop Account Confirmation Letter here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <br />
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            {bankStatus !== 'Approved' ?
                                                <div className="flex items-center justify-between">
                                                    <button onClick={(e) => onBankConfirmationUpload(e.target.value)} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                        Save
                                                    </button>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            : ''
                        }
                        {/* END Banking SECTION */}

                        {/* Wallet Address Management */}
                        {selectedMenuItem === 6 ?
                            <div>
                                <div className="mb-6 mt-6 " >
                                    <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white" id="kycfields">Wallet Address Management</h1>
                                </div>
                                {loadingWalletAddressManagement || loadingWalletAddressManagement ?
                                    <LoadingText title="Loading..." description="" />
                                    :
                                    <div>
                                        <div className="mb-2">
                                            <h5 className='text-red-500 text-sm'>
                                                Kindly be aware that once you have added your address, any changes will require an email request to info@c1wealth.co.za for verification.
                                            </h5>
                                        </div>

                                        <div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    USDT TRC-20 Wallet Address
                                                </label>
                                                <input disabled={USDTTRCWalletAddressChange ? true : false} type="text" value={USDTTRCWalletAddress} onChange={(e) => onWalletAddressUpdate('trc20', e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    USDT ERC-20 Wallet Address
                                                </label>
                                                <input disabled={USDTERCWalletAddressChange ? true : false} type="text" value={USDTERCWalletAddress} onChange={(e) => onWalletAddressUpdate('erc20', e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="mb-6">
                                                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                                                    BTC Wallet Address
                                                </label>
                                                <input disabled={BTCWalletAddressChange ? true : false} type="text" value={BTCWalletAddress} onChange={(e) => onWalletAddressUpdate('btc', e.target.value)} className="authentication-bottom-border form-control form-no-background shadow appearance-none w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

                                            </div>

                                            <div className="flex items-center justify-between">
                                                <button onClick={(e) => updateUserWalletAddress()} className="authentication-button  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                    Save
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                            : ''
                        }
                    </div>
                </form>

            </div>

        </div>
    )
}

export default Settings