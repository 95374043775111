import React from 'react'
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    InputGroup,
    ModalBody

} from "reactstrap";
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

function UploadDocumentsComponent({alreadyUploaded, alreadyUploadedText, uploadedDocuments, subText, handleAcceptedFile}) {
    return (
        <div>
            {alreadyUploaded ? <p className="text-green-500">{alreadyUploadedText}</p> :
                <div>
                    <span className='text-red-500 text-sm'>{subText}</span>
                    <div className="dropzone-previews mt-3" id="file-previews">
                        {uploadedDocuments.map((f, i) => {
                            return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                >
                                    <div className="p-2">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    style={{ maxHeight: "150px" }}
                                                    alt={f.name}
                                                    src={f.preview}
                                                />
                                            </Col>
                                            <Col>
                                                <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                >
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                    <Dropzone
                        onDrop={acceptedFiles => {
                            handleAcceptedFile(acceptedFiles)
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                                <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} type="file" name="userUpload" />
                                    <div className="dz-message needsclick">
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            }
        </div>
    )
}

export default UploadDocumentsComponent