import React, {useState, useEffect} from "react";

import { UserContext } from "../../../UserContext";
import axios from 'axios'
import PropTypes from 'prop-types'

import io from "socket.io-client"

function TransactionHandleConfirmationModal({modalOTP, countryCurrencyDisplay, blockUserFromContinue}) {

    const{auth,user} = React.useContext(UserContext)


    const handleRef = true;
    const [blockUserFromContinueState, setBlockUserFromContinueState] = useState(blockUserFromContinue);
    const [amountValue, setAmountValue] = React.useState(1);
    const [otpValue, setOtpValue] = React.useState('');
    const [reference, setReferenece] = React.useState('');

    const [accountName, setAccountName] = React.useState('');
    const [bankName, setBankName] = React.useState('');
    const [accountType, setAccountType] = React.useState('');
    const [accountNumber, setAccountNumber] = React.useState('');
    const [branchCode, setBranchCode] = React.useState('');

    const [jsonCurrencyData, setJsonCurrencyData] = useState({})
    const [exchangeFee, setExchangeFee] = useState(0)

    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [showSpiner, setShowSpinner] = useState(true)
    const [displayErroAddressMessage, setDisplayErroAddressMessage] = useState(false)
    
    
    
    const [exchangeObjectOptions, setExchangeObjectOptions] = React.useState({'12': 0, '13': 0, '14': 0, '21': 0, '23': 0, '24': 0, '31': 0, '32': 0, '34': 0,'41': 0, '43': 0, '43': 0,});

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
        'c1t' : '4',
    }

    const userAddressMeta = {
      'usdt' : 'usdt_trc_wallet_address',
      'usdt2' : 'usdt_erc_wallet_address',
      'btc' : 'usdt_btc_wallet_address',
  }

    const socket = io("https://c1wealth-backend-4d36e83a9587.herokuapp.com", {});
    window.socket = socket;


    const amountChange = (amount) => {
        modalOTP["amount"] = amount;
        setAmountValue(amount);


        if(modalOTP["type"] === "exchange") {
            handleExchange(amount);
        }
    };

    function handleExchange(e)
{
   
  var amountTobuy = e * exchangeObjectOptions[currencyConvert[modalOTP['from']] + currencyConvert[modalOTP['to']]]

  var feeTotal = (amountTobuy * 3) / 100
  if(currencyConvert[modalOTP['from']] === '3' || currencyConvert[modalOTP['to']] === '3')
  {
    feeTotal = 0;
  }
  
  modalOTP['e_fee'] = feeTotal

  var totalAmountTobuy = amountTobuy - feeTotal
//   setExchangebuy2(parseFloat(totalAmountTobuy).toFixed(2))
  modalOTP['e_amount'] = parseFloat(totalAmountTobuy)
  setExchangeAmount(parseFloat(totalAmountTobuy))


  // JSON.parse
  modalOTP['e_rate'] = (exchangeObjectOptions[currencyConvert[modalOTP['from']] + currencyConvert[modalOTP['to']]])
 
}

const [userBalance, setUserBalance] = useState({zar: 0, btc: 0, usdt: 0})
const [userUSDT, setUSDT] = useState(0)
const [userZAR, setZAR] = useState(0)
const [userBTC, setBTC] = useState(0.00)

const getUserBalance = async(userid) =>{
  var userObj = {
    userid: userid
  }

  await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
    if(res.data.status == 200)
    {
     
      setZAR(res.data.currencyInfo[1].balance)
      setUSDT(res.data.currencyInfo[0].balance)
      setBTC(res.data.currencyInfo[2].balance)
      userBalance["zar"] = res.data.currencyInfo[1].balance
      userBalance["usdt"] = res.data.currencyInfo[0].balance
      userBalance["btc"] = res.data.currencyInfo[2].balance
    }
  })

  return true;
}

React.useEffect(()=>{
    
    if(auth && user)
    {
      getUserBalance(user.user.userid)

  
      
      if(modalOTP["from"] === 'usdt' || modalOTP["from"] === 'usdt2' || modalOTP["from"] === 'btc')
      {
        getUserWalletInformation(user.user.userid, modalOTP["from"])
      }else{
        getUserInfo(user.user.userid)
      }

    }

  },[auth])

React.useEffect(() =>{
    if(modalOTP["type"] === 'exchange')
    {
    
      socket.on('currency update', onCurrencyUpdate);
      socket.emit('currency update', {});
     
    
      return () => {
       
        socket.off('currency update', onCurrencyUpdate)
      }
    }else{
      setShowSpinner(false)
    }

  }, [])

  const getUserInfo = async(userid) => {
    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
        setAccountName(res.data.info.bank_account_name)
        setBankName(res.data.info.bank_bank_name)
        setAccountType(res.data.info.bank_account_type)
        setAccountNumber(res.data.info.bank_account_number)
        if(res.data.info.bank_branch_code)
          setBranchCode(res.data.info.bank_branch_code)

        modalOTP["reference"] = res.data.info.bank_account_name + ' ' + res.data.info.bank_bank_name + ' ' + res.data.info.bank_account_type + ' ' + res.data.info.bank_account_number;
        // setShowSpinner(false)
      }

    })
  }

  const getUserWalletInformation = async(useridToUse, currencySelected) => {
    var userObj = {
      userid: useridToUse,
      meta_key: userAddressMeta[currencySelected]
    }
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-meta`,userObj).then(async (res)=>{

      if(res.data.status == 200)
      {
        setReferenece(res.data.body);
        modalOTP["reference"] = res.data.body;
    
        blockUserFromContinue(false);
      }else{
        setDisplayErroAddressMessage(true)
     
        blockUserFromContinue(true);
      }

    })
  }

const onCurrencyUpdate = (data) => {
  setShowSpinner(true)
  
    setJsonCurrencyData(data);
    
  
    var btcTousdt = data.customData.btc2usd['5. Exchange Rate']
    // var btcTozar = data.customData.btc2zar['5. Exchange Rate']
    var btcTozar = 0;
    var zarTousd = data.customData.zar2usd['5. Exchange Rate']
    var c1tToUsd = 10; // 1 USD = 10 C1T
  
    
    var usdTobtc = 1 / parseFloat(btcTousdt)
    var usdTozar = 1 / parseFloat(zarTousd)
    var usdToc1t = 1 / c1tToUsd;
    // var zarTobtc = 1 / parseFloat(btcTozar)
    var zarTobtc = 0;

    var zarToc1t = parseFloat(zarTousd) / 10
    var btcToc1t = parseFloat(btcTousdt) / 10

    var c1tToZar = usdTozar * c1tToUsd; // Convert USD to ZAR and then multiply by the number of C1T per USD
    var c1tToBtc = usdTobtc * c1tToUsd; // Convert USD to BTC and then multiply by the number of C1T per USD

  
    // '12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0
    exchangeObjectOptions['12'] = parseFloat(usdTozar);
    exchangeObjectOptions['13'] = parseFloat(usdTobtc);
    exchangeObjectOptions['14'] = parseFloat(usdToc1t);

    exchangeObjectOptions['21'] = parseFloat(zarTousd);
    exchangeObjectOptions['23'] = parseFloat(zarTobtc);
    exchangeObjectOptions['24'] = parseFloat(zarToc1t);

    exchangeObjectOptions['31'] = parseFloat(btcTousdt);
    exchangeObjectOptions['32'] = parseFloat(btcTozar);
    exchangeObjectOptions['34'] = parseFloat(btcToc1t);

    exchangeObjectOptions['41'] = parseFloat(c1tToUsd);
    exchangeObjectOptions['42'] = parseFloat(c1tToZar);
    exchangeObjectOptions['43'] = parseFloat(c1tToBtc);

    setShowSpinner(false)
  };

    const otpChange = (otp) => {
        modalOTP["otp"] = otp;
        setOtpValue(otp);
    };

    const handleReference = (reference) => {
        modalOTP["reference"] = reference;
        setReferenece(reference);
    }

    const handleBank1 = (text) => {

        setAccountName(text);
        modalOTP["reference"] = text + ' ' + bankName + ' ' + accountType + ' ' + accountNumber;
    }

    const handleBank2 = (text) => {
        setBankName(text);
        modalOTP["reference"] = accountName + ' ' + text + ' ' + accountType + ' ' + accountNumber;
    }

    const handleBank3 = (text) => {
        setAccountType(text);
        modalOTP["reference"] = accountName + ' ' + bankName + ' ' + text + ' ' + accountNumber;
    }

    const handleBank4 = (text) => {
        setAccountNumber(text);
        modalOTP["reference"] = accountName + ' ' + bankName + ' ' + accountType + ' ' + text;
    }

    const depositLabel = {
        zar: 'Reference Used',
        btc: 'TXID',
        usdt: 'TXID'
    }

    // useEffect(async res =>{
        
    //     modalOTP["reference"] = depositLabel[ modalOTP["from"]];
    //     setReferenece(depositLabel[modalOTP["from"]]);
      
    //   },[handleRef])

    function truncateDecimals(number, decimalPlaces) {
      const multiplier = 10 ** decimalPlaces;
      return Math.floor(number * multiplier) / multiplier;
    }
  


  return (
    <div>

{showSpiner ?
        <div role="status" className="m-auto">
            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
        :
    <div>

    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
          
            {modalOTP["type"] === 'exchange' ? "Enter " + countryCurrencyDisplay[modalOTP["from"]] + " Amount" : "Enter Amount"} {modalOTP['type'] !== 'deposit' ? `(MAX - ${countryCurrencyDisplay[modalOTP["from"]] }${modalOTP["from"] === "btc" ? truncateDecimals(userBTC, 6) : modalOTP["from"] === "zar" ? truncateDecimals(userZAR,2) : modalOTP["from"] === "usdt2" ?  truncateDecimals(userUSDT - 27, 2) : truncateDecimals(userUSDT, 2)})` : ''}
        </label>
        <input type="number" min="1" start="1" value={amountValue} onChange={(e) => amountChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>

    {modalOTP["type"] === 'exchange' ?
    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
            {countryCurrencyDisplay[modalOTP["to"]] + " Amount"} <p className='text-sm text-red-500 underline'>{currencyConvert[modalOTP['from']] === '3' || currencyConvert[modalOTP['to']] === '3' ? '' : '3% Fee Already Deducted'}</p>
        </label>
        <input type="number" value={exchangeAmount} disabled className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    : ''}

    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" for="username">
            OTP
        </label>
        <input value={otpValue} onChange={(e) => otpChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    {modalOTP['type'] === 'deposit' ?
    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" for="username">
            {depositLabel[ modalOTP["from"]]}
        </label>
        <input value={reference} onChange={(e) => handleReference(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    : 

        modalOTP["type"] !== 'exchange' ?
            <div className="mb-4">

                {modalOTP["from"] === 'zar' ? 
                <div>
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Name</label>
                    <input value={accountName} disabled='disabled' onChange={(e) => handleBank1(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Bank Name</label>
                    <input value={bankName} disabled='disabled' onChange={(e) => handleBank2(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Type</label>
                    <input value={accountType} disabled='disabled' onChange={(e) => handleBank3(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Number</label>
                    <input value={accountNumber} disabled='disabled' onChange={(e) => handleBank4(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Branch Code</label>
                    <input value={branchCode} disabled='disabled' className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                </div>
                : 
                <div>
                  {displayErroAddressMessage ?
                  <p className="text-red-500 text-sm">No withdrawal address found for this currency. Please ensure to add your address on the settings page.</p>
                : ''}
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">{modalOTP["from"] === 'usdt' ? 'USDT ' : ''} Wallet Address</label>
                    <input value={reference} disabled='disabled' onChange={(e) => handleReference(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                </div>
                }
            </div>
        : ''
    
    }

</div>}
</div>
  )
}

TransactionHandleConfirmationModal.propTypes = {
    modalOTP:PropTypes.any,
    countryCurrencyDisplay: PropTypes.any,
    blockUserFromContinue: PropTypes.any,
}


export default TransactionHandleConfirmationModal