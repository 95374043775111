import React, { useState, useEffect } from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({ userid }) {

    const [loanInfo, setLoanInfo] = useState(null)

    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };

        fetchData();
    }, [userid]);

    const getUserLoanOptions = async () => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-loan-info", userObj).then(async (res) => {
            
            if (res.data.status === 200) {
                setLoanInfo(res.data.data)
            }
        });
    }
    return (
        <div id="laon-info-text" className="container-md px-4 pl-6">
            <div className="w-full">

                <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                    <div className="mb-4">
                        <h1 className="title text-white text-center mb-2">Stable Fund Loan</h1>

                        {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                            <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">
                                <p className="mb-4">
                                    A seamless way to borrow, swap, and grow digital assets without liquidation. With
                                    competitive rates and flexible terms, borrowers can easily deposit collateral and receive
                                    their loan quickly. In case of repayment challenges, the system ensures automatic
                                    repayment or collateral liquidation. Plus, unlock additional benefits, including leveraging
                                    assets for an internal index fund and a 3X option to multiply holdings
                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Immediate Benefits</h3>
                                <p className="pl-5 mb-4">
                                    Deposit your funds and access 70% instantly for immediate use. The
                                    remaining 30% works behind the scenes to grow your wealth magically.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Strategic Growth</h3>
                                <p className="pl-5 mb-4">
                                    Our investment strategy ensures the growth of the 30% portion to cover
                                    the 70% utilized, leading to full loan repayment within 5 years or 60
                                    months, thanks to our thoughtful investments.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Interest to Invest</h3>
                                <p className="pl-5 mb-4">
                                    With an annual interest rate of just 5% on the remaining loan amount,
                                    your investment works smarter, not harder, in achieving monumental
                                    growth.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Full Circle Financing</h3>
                                <p className="pl-5 mb-4">
                                    At the end of the 5-year journey, your loan is fully repaid, and your initial
                                    deposit is returned in full. You receive 100% of your funds back,
                                    reassured that your investment has been steadily growing throughout
                                    the process.

                                </p>


                            </div>
                            : ''}

                        <div>
                            <div className="p-2">
                                <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Future Payout Amount (USDT): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInUSDT : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Future Payout Amount (ZAR/NAD): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInRand : "N/A"}</span>
                            </div>


                            <div className="flex items-center justify-between">
                                <a href="./my-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    View My Loan Products
                                </a>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default LoanHistory