import React, { useState, useEffect } from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({ userid }) {

    const [loanInfo, setLoanInfo] = useState(null)

    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };

        fetchData();
    }, [userid]);

    const getUserLoanOptions = async () => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-crypto-loan-ltv-info", userObj).then(async (res) => {
      
            if (res.data.status === 200) {
                setLoanInfo(res.data.data)
            }
        });
    }
    return (
        <div id="laon-info-text" className="container-md px-4 pl-6">
            <div className="w-full">

                <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                    <div className="mb-4">
                        <h1 className="title text-white text-center mb-2">Crypto LTV Loan</h1>

                        {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                            <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">

                                <p className="mb-4">
                                    Crypto LTV Loan - Our product allows you to leverage your Bitcoin (BTC) or Ethereum (ETH) holdings to secure a 
                                    loan. This disclosure outlines the terms, conditions, and repayment structure for loans at various 
                                    Loan-to-Value (LTV) ratios, including the repayment schedule.
                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Loan Options</h3>
                                <p className="pl-5 mb-4">
                                You can choose to put either BTC or ETH into the product and secure a loan against it. 
                                The available LTV ratios are 50%, 20%, and 35%.
                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">50% LTV Loan</h3>
                                <p className="pl-5 mb-4">Collateral: 1 BTC or equivalent in ETH
                                    Loan Amount: 50% of the collateral value</p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Repayment Structure</h3>
                                <p className="pl-5 mb-4">
                                The loan repayment will be structured such that you receive back a percentage of the loan amount every month in 
                                the actual currency you borrowed against. This monthly repayment will effectively pay back your loan over
                                 5 years (60 months).

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Monthly Repayment Percentage</h3>
                                <p className="pl-5 mb-4">
                                Approximately 2% of the original loan amount per month.
                                </p>
                            </div>
                            : ''}

                        <div>
                            <div className="p-2">
                                <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                            </div>


                            <div className="flex items-center justify-between">
                                <a href="./my-crypto-ltv-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    View My Loan Products
                                </a>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default LoanHistory