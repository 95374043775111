// components/LineChart.js
import React from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

import aitExternal from '../../../assets/uploads/ai_buy_now.svg';


import axios from 'axios'

function ExterNalProducts({openProductModal}) {


  return (
    <div className="tour-external-products"> 
      <div>My <span className="primary-color">C1 External Products <FontAwesomeIcon  className="pl-2" icon={faCaretDown} size="xl"  /></span></div>
        
      <a href="#" onClick={openProductModal}>
        <div className="p-2 external_img">
          <img src={aitExternal} />
        </div>
      </a>
    </div>
  );
}

ExterNalProducts.propTypes = {
  openProductModal: PropTypes.func
}

export default ExterNalProducts;