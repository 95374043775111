import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function FundingOptions({changeStepProcess, closeProductModal}) {
  return (
    <div>
        <h2 className="text-center">Funding The Wallet</h2><br />

        <p>We provide the following 2 options to fund the wallet at the moment:</p><br />

        <ul>
            <li>C1 Wealth Wallet - This option allows you to buy AI Tokens with the available funds in your wallet. Once a purchase is made,
                 one of the C1 Wealth Accounts team will allocate these tokens to your wallet. This can take up to 24H to reflect in you wallet.
            </li>

            <br />

            <li>New Deposit - Our wallet options and wallet address info will be displayed and this will allow you to buy the token from your own BTC or USDT wallet. 
                You will then be required to provide us with your TXID and AIT Wallet address so that one of the C1 Wealth Accounts team can allocate these tokens to your wallet. This can take up to 24H to reflect in you wallet.
            </li>
        </ul>

        <br />

        {/* Navigation Buttons */}
        <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(7)}}>C1 Wealth Wallet</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(4)}}>New Deposit</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(1)}}>Back</Button>
        </div>
            
    </div>
  )
}

FundingOptions.propTypes = {
    closeProductModal: PropTypes.func,
    changeStepProcess:PropTypes.func,
}

export default FundingOptions