import React from 'react'
import { UserContext } from '../../../UserContext';
import C1_Crypto_Education from './C1_Crypto_Education.pdf'
import C1_Crypto_Education_Guide_Image from './C1_Crypto_Education_Guide_Image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function EducationContainer() {
    const { auth, user } = React.useContext(UserContext);
    const [displayEducationContainer, setDisplayEducationContainer] = React.useState(true);
  
    const toggleNotificationEducationContainer = () => {
      setDisplayEducationContainer(!displayEducationContainer);
    }
  
    return (
      <div>
        <div>
          My <span className="primary-color">Education <FontAwesomeIcon onClick={toggleNotificationEducationContainer} className="pl-2" icon={faCaretDown} size="xl"  /></span>
        </div>
        {displayEducationContainer ?
          <div className="mt-2">
            <p className="text-white">Click to download the <span className="primary-color"><a target='BLANK' rel="noopener noreferrer" href={C1_Crypto_Education}>C1 Crypto Education Guide</a></span></p>
            
            {/* Image here */}
            <div className="relative">
              <a href={C1_Crypto_Education} target='BLANK' rel="noopener noreferrer"  >
                <img src={C1_Crypto_Education_Guide_Image} alt="My Crypto Education Guide Image" className="w-full h-auto max-h-96 object-cover" />
                <div className="absolute inset-0 bg-gray-800 opacity-50 rounded-lg"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  {/* <div className="relative bg-primary-color py-2 px-4 rounded-lg hover:bg-primary-color-dark transition duration-300"
                    style={{background: "black", opacity: "0.5",color: "white"}}>
                    <span className="glass-effect"></span>
                    <span className="text-opacity-50">My Products</span>
                    </div> */
                  }
                </div>
              </a>
            </div>
          </div>
          : ''}
      </div>
    )
  }
export default EducationContainer