import React from 'react';

import Futuristic_saleCoR_39 from "./uploads/Futuristic_sale-CoR_39.pdf"
import Terms from "./uploads/Terms & Conditions.pdf"
import Risk from "./uploads/Risk Disclosure.pdf"
import VAT from "./uploads/Registration VAT C1 Wealth.pdf"
import Privacy from "./uploads/Privacy Policy.pdf"
import COR14 from "./uploads/COR14.3 C1 WEALTH MANAGEMENT.pdf"


const DocumentTable = ({ documents }) => {
  return (
    <table className="border-collapse w-full">
      <thead>
        <tr>
          <th className="p-3 font-bold uppercase bg-transparent primary-c1-text border primary-border hidden lg:table-cell">
            Document Name
          </th>
          <th className="p-3 font-bold uppercase bg-transparent primary-c1-text border primary-border hidden lg:table-cell">
            Download
          </th>
        </tr>
      </thead>
      <tbody>

      <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              Terms And Conditions
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={Terms} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>

          <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              Risk Disclosure
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={Risk} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>

        <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              C1 Wealth VAT Registration
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={VAT} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>

          <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              Privacy Policy
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={Privacy} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>

          <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              C1 WEALTH MANAGEMENT - COR14.3 
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={COR14} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>

          <tr className="bg-transparent flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-white text-center border primary-border block lg:table-cell relative lg:static">
              Futuristic sale - CoR 39
            </td>
            <td className="w-full lg:w-auto p-3 text-white text-center primary-border block lg:table-cell relative lg:static">
              <a href={Futuristic_saleCoR_39} target="_blank" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Download
              </a>
            </td>
          </tr>



      </tbody>
    </table>
  );
};

export default DocumentTable;