import React, { useState, useEffect } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'
import TourGuide from "../../components/Modals/Tours";
import ExchangeForm from "../../forms/ExchangeForm";
import ExchangeLoanForm from "../../forms/Loans/ExchangeForm";

function Exchange() {

  const [modalOTP, setModalOTP] = React.useState({ otp: '', from: '', amount: '', type: 'exchange', reference: '', e_amount: 0, e_fee: 0, e_rate: 0 });

  const [modalDisplay, setModalDisplay] = React.useState(false);

  const [showSpiner, setShowSpinner] = useState(true)
  const [countryCurrency, setCountryCurrency] = React.useState('ZAR');
  const { auth, user } = React.useContext(UserContext)
  const [userid, setUserid] = React.useState('');

  const [tourSteps, setTourSteps] = React.useState([]);
  const [allowTourClose, setAllowTourClose] = React.useState(false);

  // Loans
  const [loanModalDisplay, setLoanModalDisplay] = React.useState(false);

  React.useEffect(() => {
    // window.location.href="/dashboard"
    if (auth != null) {
      if (!auth) {
        window.location.href = "/login"
      } else if (auth && user) {
        getUserDetails(user.user.userid)
        getFirstExchange(user.user.userid)
        setUserid(user.user.userid)
      }
    }
  }, [auth])

  const currencyConvert = {
    'usdt': '1',
    'zar': '2',
    'btc': '3',
    'c1t': '4',
  }


  const getUserDetails = async (userid) => {
    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`, userObj).then(async (res) => {
      if (res.data.status == 200) {
        var mainString = res.data.info.country
        if (mainString && mainString.includes("Namibia")) {
          setCountryCurrency('NAD')
        }
      }
    });
  }

  const getFirstExchange = async (userid) => {

    var exchangeObj = {
      userid: userid
    }

    await axios.post(process.env.REACT_APP_BASE + "api/confirm-any-exchange", exchangeObj).then(async (res) => {

      if (res.data.status === 400) {
        var tourObj = [

          {
            element: '.exchange-explenation',
            popover:
            {
              title: 'Exchange Information', description:
                "Before proceeding with your first exchange, please carefully review all provided information, including wallet and bank details."
            }
          },

          {
            element: '.exchange-button',
            popover:
            {
              title: 'Ready To Exchange', description: 'After reviewing all the information, please click on the "Exchange Now" button to proceed with your first exchange.'
            }
          },

        ]

        setTourSteps(tourObj)
        setAllowTourClose(true)
      }
    });


  }

  const openTransactionModal = () => {
    setShowSpinner(false)
    setModalDisplay(true)

  }

  const closeModal = () => {
    setModalDisplay(false)
  }

  const sendOTP = async () => {

    var otpObj = {
      userid: userid
    }

    await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp", otpObj).then((res) => {

      if (res.data.status == 200) {

        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });



      } else {

        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    })

  }

  const handleAccept = async () => {

    setShowSpinner(true)

    if (modalOTP['amount'] <= 0) {
      toast.error('Please Enter Valid Amount', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setShowSpinner(false)

      return false;
    }

    if (modalOTP['e_amount'] <= 0) {
      toast.error('Unable To Create Exchange', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setShowSpinner(false)

      return false;
    }

    var otpObj = {
      userid: userid,
      otp: modalOTP['otp']
    }

    const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

      if (res.data.status !== 200) {

        toast.error("Incorrect OTP, We Have Sent A New OPT!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowSpinner(false)

        await sendOTP();

        return false;
      } else {
        return true;
      }
    });

    if (!checkOtp)
      return false;


    var balanceObject = {
      userid: userid,
      currency: currencyConvert[modalOTP['from']]
    }




    var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject).then(async res => {


      if (parseFloat(res.data.total) < modalOTP['amount']) {
        toast.error('Insufficient Balance!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowSpinner(false)

        return false;
      } else {
        return true
      }

    })

    if (!checkBalance)
      return false;

    var createExchange = await exchangeAccept();
  }

  const exchangeAccept = async () => {

    setShowSpinner(true)

    const exchangeObject = {
      userid: userid,
      excahnge_type: '1',
      amount_from: currencyConvert[modalOTP['from']],
      amount_to: currencyConvert[modalOTP['to']],
      status: '0',
      amount: parseFloat(modalOTP['e_amount']),
      from_amount: parseFloat(modalOTP['amount']),
      exchange_rate: parseFloat(modalOTP['e_rate']),
      exchange_amount: modalOTP['e_amount'] + modalOTP['e_fee'],
      fee: modalOTP['e_fee'],
    }



    //  return false;

    await axios.post(process.env.REACT_APP_BASE + "api/create-exchange", exchangeObject).then(async res2 => {

      if (res2.data.status == 200) {
        toast.success('Exchange Created Successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => { window.location.reload(); }, 2000);
      } else {
        toast.error('Unable To Create Exchange', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    })
  }

  // Loans
  const openLoanTransactionModal = () => {
    setShowSpinner(false)
    setLoanModalDisplay(true)

  }

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

      {modalDisplay ?
        // <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} title="Exchange" showSpiner={showSpiner} />
        <ExchangeForm />
        :

        loanModalDisplay ?
        <ExchangeLoanForm />
        :
        <div>
          <TourGuide steps={tourSteps} allowClose={allowTourClose} />

          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Exchange</h1>
          

          <div className="container-md mx-auto px-4">


            <div className="container-md px-4 primary-br-2">

              <div className="exchange-explenation">
                <ol type="1">
                  <li>Exchanges can be made between currencies ({countryCurrency}, USDT & Bitcoin), and a 3% fee will be automatically calculated and deducted during the exchange process. Please note that this fee is applicable only to certain designated currencies.</li>
                  <li>The exchange will remain pending until approved by C1 Wealth Admin which can take up to 24 hours.</li>

                </ol>

              </div>

              <div className='m-auto text-center'>
                <button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center exchange-button'>Wallet Exchange</button>
              </div>

              <div className='m-auto text-center'>
                <button onClick={openLoanTransactionModal} className='c1-primary-btn mt-5 text-center exchange-button'>Loan Wallet Exchange</button>
              </div>

            </div>



          </div>

        </div>
      }

    </div>
  )
}

export default Exchange