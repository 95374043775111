import React, {useState, useEffect} from "react";
import {Page, Text, Font, Image, View, Document, StyleSheet} from '@react-pdf/renderer';

import c1Logo from '../../assets/uploads/logo/c1logo.png'


function PDFProducts({title, name, info, userid, date, currency, idnumber}) {

  // const currency = {
  //     '1': 'USDT',
  //     '2': 'ZAR',
  //     '3': 'BTC'
  // }

  const status = {
    '1': 'Approved',
    '2': 'Declined',
    '0': 'Pending'
  }



  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      //paddingBottom: 10,
      paddingHorizontal: 35,
    },

    title: {
      fontSize: 20,
      textAlign: 'left',
      fontFamily: 'Oswald'
    },

    horizontalRule: {
      borderBottomWidth: 1,
      borderBottomColor: '#7bc2d8',
      marginVertical: 10, // Adjust as needed
    },

    author: {
      fontSize: 18,
      textAlign: 'center',
      marginTop: 10,
    },

    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },

    image: {
      marginVertical: 10,
      height: "70px",
      width: "70px",
    },

    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },

    inlineBlock: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between", // Aligns items along the main axis
      fontSize: 14,
    },

    inlineBlock2: {
      // marginTop: "20px",
      backgroundColor: "#AACFDB",
      color: "white",
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
      //borderBottom: "0.5px solid black",
      display: "flex",
    },

    inlineBlock3: {
      color: "black",
      borderTop: "0.1px solid #d0d6d9",
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
    },

    tableBlock: {
      marginTop: "20px",
      borderTop: "0.8px solid #d0d6d9",
      borderLeft: "0.8px solid #d0d6d9",
      borderRight: "0.8px solid #d0d6d9",
      borderBottom: "0.8px solid #d0d6d9",
    },

    pageNumberStyle: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },

    footer: {
      marginTop: 'auto',
      fontSize: 9,
      paddingHorizontal: 10,
    },

    footerText: {
      fontSize: 9,
      textAlign: 'center',
    },

  });

  const Footer = () => (
    <View style={{ marginTop: 'auto', fontSize: 9, paddingHorizontal: 10 }}>
      {/* <Text style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>
        Page {pageNumber} of {totalPages}
      </Text> */}
      <Text
      style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )}
        fixed
      />
      <View style={{ borderBottomWidth: 1, borderBottomColor: '#7bc2d8', marginVertical: 10 }} />
      <Text style={{ fontSize: 9, textAlign: 'center' }}>
        C1 Wealth Management (Pty) Ltd, Company Registration: 2022/837320/07
      </Text>
      <Text style={{ fontSize: 9, textAlign: 'center' }}>
        Block C, The Old Tannery, Hermon Road, Wellington, 7654
      </Text>
    </View>
  );
      

  return (
    <Document>
      <Page style={styles.body}>
        <Image
          style={styles.image}
          src={c1Logo}
        />
        <Text style={styles.title}>C1 Wealth</Text>
        <View style={styles.horizontalRule} />
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text >{name}</Text>
            <Text style={{ fontWeight: "bold" }}>Customer ID: {userid}</Text>
            <Text style={{ fontWeight: "bold" }}>ID / Passport: {idnumber}</Text>
          </View>
          <View style={{ flex: 1, textAlign: "right" }}>
            <Text style={{ fontWeight: "bold" }}>Date: {date}</Text>
          </View>
        </View>
        <Text style={styles.author}>{title}</Text>
        
        {info.map((transaction,key) => (

          <View style={styles.tableBlock} key={key}>
              <View style={styles.inlineBlock2}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text >Date</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >Name</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >Description</Text>
                </View>
                <View style={{ flex: 2, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >Plan ID</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >Amount</Text>
                </View>
                {/* <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                  <Text >Total</Text>
                </View> */}
              </View>
        
              <View style={styles.inlineBlock3} key={key} wrap={false}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text >{transaction.invest_date}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >{name}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >Contribution Paid {`Expire: ${transaction.expire_date}`}</Text>
                </View>
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >{transaction.planid}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >{currency[transaction.currency] + transaction.amount}</Text>
                </View>
                {/* <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                  <Text >{currency[transaction.currency] + transaction.amount}</Text>
                </View> */}
              </View>

              {transaction.interests.map((interest,key) => (
                  
              <View style={styles.inlineBlock3} key={key} wrap={false}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >{interest.date}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                <Text >{name}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                <Text >Interest Paid</Text>
              </View>
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                <Text >{interest.planid}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                <Text >{currency[transaction.currency] + interest.amount.toFixed(2).toString()}</Text>
              </View>
              {/* <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                <Text >{currency[transaction.currency] + interest.total.toFixed(2).toString()}</Text>
              </View> */}
              </View>
              ))}
        
          </View>
        ))}

<Footer  />
      </Page>
    </Document>
  )
}



export default PDFProducts