import React from 'react'
import DocumentTable from './DocumentTable';


function Documents() {

    const documents = [
        { 
            name: 'Futuristic sale - CoR 39',
            url: `${process.env.PUBLIC_URL}/uploads/Futuristic_sale-CoR_39.pdf`
        },

      ];

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Documents</h1>

        <div className="container-md mx-auto px-4">

            <h5 className='text-white'>Legal and compliance documentation</h5>
            <div className="container-md mt-3 primary-br-2">
                <DocumentTable documents={documents} />   
            </div>

        </div>

    </div>
  )
}

export default Documents