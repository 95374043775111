import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { withRouter, Link, useLocation } from "react-router-dom"

import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { useTable } from 'react-table'

import ProductTable from "./ProductTable";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SingeProduct() {

  const{auth,user} = React.useContext(UserContext)
  const[foundSingleProduct, setFoundSingleProduct] =  React.useState(true);
  const[productData, setProductData] =  React.useState([]);
  const[productInfo, setProductInfo] =  React.useState(null);
  const[clientPlanWalletInfo, setClientPlanWalletInfo] =  React.useState(null);
  const[productDisplayData, setProductDisplayData] =  React.useState([]);
    
    
  let query = useQuery()
  let userToken = query.get("pid");


  React.useEffect(()=>{
  
    if(auth && user){

            
      if(userToken !== null && userToken !== undefined && userToken !== '')
      {
               
        var singleProduct = getSingleProduct(user.user.userid)

      }else{

        // window.location.href="/login"

      }
          
    }
  
  },[auth])

  const getSingleProduct = async(userid) => {
      
    var userObj = {
      userid: userid,
      pr_id: userToken
    }
      
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/single-user-open-products`,userObj).then(async (res)=>{
      if(res.data.status == 200){

        setProductDisplayData(res.data.total.interests)
        setProductData(res.data.total)
        setFoundSingleProduct(true) 
        await getProductInfo(res.data.total.product_id);
        await getClientPlanWalletInfo(userid, res.data.total.planid)
      }
    })

  }

  const getProductInfo = async(product_id) => {

    var userObj = {
      productId: product_id
      }
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}product-info-by-id`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
          setProductInfo(res.data.Product)
      
        }
      })

  }

 
  const getClientPlanWalletInfo = async(userid, planid) => {

    var userObj = {
      userid: userid,
      planid: planid
      }

  
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}client-plan-wallet-by-planid`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
          setClientPlanWalletInfo(res.data.clientPlanWallet)
         
        }
      })

  }

  var status = {
    '0': "Pending",
    '1': "Approved",
    '2': "Declined",
    '3': "Closed",
  }

  const data = React.useMemo(
  () => 
    productDisplayData,
    []
  )
    

  const columns = React.useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'month', // accessor is the "key" in the data
      },
      {
        Header: 'Percentage',
        accessor: 'percentage'
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ],
    []
  )
    
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })


  return (
    <div className="container-md mx-auto px-4 mt-6 flex justify-center">
      <div className="flex flex-col md:flex-row">
        {/* Left Container */}
        <div className="md:w-2/8 single-product-user-info">
          {foundSingleProduct && typeof productData !== undefined ?
            <div>
              <div>
                <div className="p-2">
                  <span className="primary-color">Plan ID: </span>{productData.planid}
                </div>
                <div className="p-2">
                  <span className="primary-color">Invested Date: </span>{productData.invest_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Invested Amount: </span>{productData.amount}
                </div>
                <div className="p-2">
                  <span className="primary-color">Start Date: </span>{productData.start_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Expire Date: </span>{productData.expire_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Status: </span>{status[productData.status]}
                </div>
                <div className="p-2">
                  <span className="primary-color">Product: </span>{productInfo ? productInfo.name : ""}
                </div>

                {productInfo && productInfo.toWallet === "2" ?
                       <div className="primary-br-2 primary-bb-2 p-2">
                       <span className="primary-color">Product Balance: </span>{clientPlanWalletInfo ? clientPlanWalletInfo.current_balance : ""}
                   </div>
            : null}

              </div>
            </div>
            : 'No Product Found'}
        </div>
        {/* Right Container */}
        <div className="md:w-6/8 mt-4 md:mt-0 ml-4 md:ml-10 flex justify-center">
          {foundSingleProduct && typeof productData !== undefined ?
            <div className="single-product-user-table p-4">
              <div className="mt-4">
              <ProductTable columns={columns} data={productDisplayData} />
              </div>
            </div>
          : null}
        </div>
      </div>
    </div>


  );

}

export default SingeProduct;