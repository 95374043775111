import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'



// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function SingleWithdrawal({key, transaction, refreshWithdrawals, currency}) {
    const [showAlert, setShowAlert] = useState(false);


    const renderSwitch = async(param) => {
        switch(param) {
            case '1':
              
                return 'plus';
            case '2':
                return 'minus';
            case '3':
                return 'minus';
            case '4':
                return 'plus';
            case '5':
                return 'minus';
            case '6':
                return 'plus';
            case '7':
                return 'plus';
            case '8':
                return 'plus';
            default:
                return 'plus';
        }
      }

      var productIcons = {
        "1": faPlus,
        "2": faMinus,
        "3": faMinus,
        "4": faPlus,
        "5": faMinus,
        "6": faPlus,
        "7": faPlus,
        "8": faPlus,
        "9": faMinus,
        "10": faMinus,
        "11": faMinus,
        "12": faPlus,
    }

    var productIcons2 = {
        "1": 'plus',
        "2": 'minus',
        "3": 'minus',
        "4": 'plus',
        "5": 'minus',
        "6": 'plus',
        "7": 'plus',
        "8": 'plus',
        "9": '-',
        "10": 'minus',
        "11": 'minus',
        "12": "plus",
    }

    // var currency = {
    //     '1' : 'USDT',
    //     '2' : 'ZAR',
    //     '3' : 'BTC'
    // }

    var status = {
        '0' : 'Pending',
        '1' : 'Approved',
        '2' : 'Cancelled / Declined'
    }

    var color = {
        '0' : 'orrange',
        '1' : 'green',
        '2' : 'red'
    }

    function numberWithSpaces(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }

    const cancelWithdrawalButton = async() => {
        var cancel = await cancelWithdrawal(transaction.id, transaction.userid, transaction.trans_id)
        
        var refresh = refreshWithdrawals();
        setShowAlert(false)
    }

    const showCancelModal = async() =>{
      
        setShowAlert(true)
    }

    const cancelWithdrawal = async(wID, userid, transid) => {
        var userObj = {
            withdrawalID : wID,
            userid: userid,
            transid: transid
        }
       
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/cancel-user-withdrawal`,userObj).then(async (res)=>{
            if(res.data.status == 200)
            {
       
              return true
              
            }
          })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #00c4f4',
      };

      const closeModal = (event, reason) => {
     
        if (reason && reason == "backdropClick") 
            return;
        setShowAlert(false)
      }

      const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Set initial state based on screen width

      const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768); // Update isMobile state on window resize
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleWindowResize); // Add event listener for window resize
        return () => {
          window.removeEventListener('resize', handleWindowResize); // Remove event listener on component unmount
        };
      }, []);



  return (
    <div>

    <Modal
      open={showAlert}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"

      className="form-no-background"
    >
      <Box 
      sx={{
        ...style, width: 400,
        background:
          "	rgba(5,0,5,0.9)"
      }}>
        <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>Cancel Withdrawal </h2>

        <p>Are you sure you want to cancel this withdrawal?</p>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid green",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={cancelWithdrawalButton}>Confirm</Button>

            <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>

     
      </Box>
    </Modal>
    <div className="grid grid-cols-2 mt-2 border-t border-white p-2">
        <div className="grid grid-cols-3">
          {transaction.status === '0' ? (
            <div className="m-auto">
              <div className="col-span-2 ...">
                <button onClick={showCancelModal}>
                  <h2>
                    {' '}
                    <FontAwesomeIcon icon={faTimes} size="xl" style={{ color: 'red' }} className="inline-block text-center ml-3" />
                  </h2>
                  <p className="text-sm">Cancel</p>
                </button>
              </div>
            </div>
          ) : transaction.status === '1' ? (
            <div className="m-auto">
              <FontAwesomeIcon icon={faCheck} size="xl" style={{ color: 'green' }} className="inline-block text-center" />
            </div>
          ) : (
            <div className="m-auto">
              {' '}
              <FontAwesomeIcon icon={faTimes} size="xl" style={{ color: 'red' }} className="inline-block text-center ml-3" />
            </div>
          )}
          <div className="col-span-2 ...">
            <p className="text-sm">{transaction.createdAt}</p>
            <h4 className="text-sm">{transaction.w_address} <span className="text-sm" style={{ color: color[transaction.status] }}>({status[transaction.status]})</span></h4>
          </div>
          {isMobile && (
            <div className={`col-span-3 ${isMobile ? 'text-center' : 'text-right'} mt-2`}>
              <h1>{currency[transaction.currency]} {(transaction.amount > 0 ? numberWithSpaces(parseFloat(transaction.amount)) : numberWithSpaces(parseFloat(transaction.amount) * -1))}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SingleWithdrawal.propTypes = {
    modalOTP:PropTypes.func,
    refreshWithdrawals:PropTypes.any,
    key: PropTypes.any,
    currency: PropTypes.any
}

export default SingleWithdrawal