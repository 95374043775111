import { Button } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../../../../../UserContext'
import axios from 'axios'

import io from "socket.io-client"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ExchangeForm({changeStepProcess, changeSelectedFromCurrency, selectedCurrency, selectedCurrencyDisplay}) {

    const socket = io("https://c1wealth-backend-4d36e83a9587.herokuapp.com", {});
    window.socket = socket;

    const [userUSDT, setUSDT] = useState(0)
const [userZAR, setZAR] = useState(0)
const [userBTC, setBTC] = useState(0)
const [userid, setUserid] = useState('')
const [amount, setAmount] = useState(0)
const [jsonCurrencyData, setJsonCurrencyData] = useState({})
const [exchangeAmount, setExchangeAmount] = useState(0)

const [exchangeNewAmount, setExchangeNewAmount] = useState(0)
const [exchangeFee, setExchangeFee] = useState(0)
const [exchangeRate, setExchangeRate] = useState(0)
const [otpValue, setOtpValue] = React.useState('');

const [formLevel, setFormLevel] = useState(1)

const[wallet_address, setWalletAddress] =  React.useState('');
const[showSpiner, setShowSpinner] =  React.useState(true);
const[currencyConvert, setCurrencyConvert] =  React.useState({'usdt' : '1','zar' : '2','btc' : '3'});



const{auth,user} = React.useContext(UserContext);

React.useEffect(() =>{
  
   
    socket.on('ait update', onCurrencyUpdate);
    socket.emit('ait update', {});
   
  
    return () => {
    
      socket.off('ait update', onCurrencyUpdate)
    }
  }, [])

React.useEffect( () => {

    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
      
        setUserid(user.user.userid)
        getUserBalance(user.user.userid)
        sendOTP(user.user.userid)
      }
    }
  }, [auth])

const getUserBalance = async(userid) =>{
  var userObj = {
    userid: userid
  }

  await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
    if(res.data.status == 200)
    {
   
      setZAR(res.data.currencyInfo[1].balance)
      setUSDT(res.data.currencyInfo[0].balance)
      setBTC(res.data.currencyInfo[2].balance)
 
    }
  })

  return true;
}

const sendOTP = async (user) => {

    var otpObj = {
        userid: user
      }

    await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
      
  if(res.data.status == 200)
  {
 
    toast.success('OTP Pin Successfully Sent!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });

  }else{
  
    toast.error('Unable To send OTP!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }
  
})

  }

const amountChange = (amount) => {
    setAmount(amount);
    handleExchange(amount);
};

const [exchangeObjectOptions, setExchangeObjectOptions] = React.useState({'12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0});

const onCurrencyUpdate = (data) => {
 
    try{

    setJsonCurrencyData(data);
  

    var btcTousdt = data.customData.btc2usd['5. Exchange Rate']
    // var btcTozar = data.customData.usdt2usd['5. Exchange Rate']
    var btcTozar = 1;

    var zarTousd = data.customData.zar2usd['5. Exchange Rate']
  
  
    var usdTobtc = 1 / parseFloat(btcTousdt)
    var usdTozar = 1 / parseFloat(zarTousd)
    var zarTobtc = 1 / parseFloat(btcTozar)
    
  
    // '12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0
    exchangeObjectOptions['11'] = parseFloat(btcTozar);
    exchangeObjectOptions['12'] = parseFloat(usdTozar);
    exchangeObjectOptions['12'] = parseFloat(usdTozar);
    exchangeObjectOptions['13'] = parseFloat(usdTobtc);
    exchangeObjectOptions['21'] = parseFloat(zarTousd);
    exchangeObjectOptions['23'] = parseFloat(zarTobtc);
    exchangeObjectOptions['31'] = parseFloat(btcTousdt);
    exchangeObjectOptions['32'] = parseFloat(btcTozar);
 

    setShowSpinner(false)
    }catch(e){
        console.log('echange data is ', e);
    }
  };

  function handleExchange(e)
  {
     
    var amountTobuy = e * exchangeObjectOptions[currencyConvert[selectedCurrency] + currencyConvert["usdt"]]
  

    var feeTotal = 0;
    
    if(selectedCurrency !== "usdt")
    {
      feeTotal = (amountTobuy * 3) / 100
    }
    setExchangeFee(feeTotal)
    var totalAmountTobuy = amountTobuy - feeTotal

  setExchangeNewAmount(parseFloat(totalAmountTobuy))
    setExchangeAmount(parseFloat(totalAmountTobuy))
  

    setExchangeRate((exchangeObjectOptions[currencyConvert[selectedCurrency] + currencyConvert["usdt"]]))
   
  }

//   const currencyConvert = {
//     'usdt' : '1',
//     'zar' : '2',
//     'btc' : '3',
// }


const submitExchangeForm = async() => {
  setShowSpinner(true)
    if(selectedCurrency === "btc")
    {
        if(userBTC < exchangeAmount)
        {
            toast.error('Insufficient Balance!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
      
                return false;
        }
    }
    
    if(selectedCurrency === "zar")
    {
        if(userZAR < exchangeAmount)
        {
            toast.error('Insufficient Balance!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
      
                return false;
        }
    }
    
    if(selectedCurrency === "usdt")
    {
        if(userUSDT < exchangeAmount)
        {
            toast.error('Insufficient Balance!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
      
                return false;
        }
    } 
 var otpSent = await checkOTPPin();

 setShowSpinner(false)
//  return;
 if(!otpSent)
 {
    await sendOTP(userid)
 }else{
    changeStepProcess(6)
 }
}

const checkOTPPin = async() => {
    var otpObj = {
        userid: userid,
        otp: otpValue
      }


      
    const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
             
        if(res.data.status !== 200)
        {

            toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                await sendOTP();

            return false;
        }else{
        
            var createnewExchange = await exchangeAccept()
            changeStepProcess(6)
            return true;
        }
    });

}

const exchangeAccept = async () => {

    const exchangeObject = {
        userid: userid,
        excahnge_type: '1',
        amount_from: currencyConvert[selectedCurrency],
        amount_to: '5',
        status: '0',
        amount: parseFloat(exchangeAmount),
        from_amount: parseFloat(amount),
        exchange_rate: parseFloat(exchangeRate),
        exchange_amount: exchangeAmount + exchangeFee,
        fee: exchangeFee,
        wallet_address: wallet_address
      }



  //  return false;

  await axios.post(process.env.REACT_APP_BASE + "api/create-exchange-aitoken",exchangeObject).then(async res2 =>{

      if(res2.data.status == 200)
      {
        setFormLevel(2)

        return true;

      }else{
        toast.error("Unable to create exchange!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        return false;
      }

  })
  }

  return (
    <div>

{showSpiner ?
        <div role="status" className="m-auto">
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
        :
    <div>

{/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          /> */}
        <h2 className="text-center">Exchanging From {selectedCurrencyDisplay}</h2><br />

        <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
                {selectedCurrencyDisplay} (MAX - {selectedCurrency === "btc" ? userBTC : selectedCurrency === "zar" ? userZAR : userUSDT})
            </label>
            <input type="number" min="1" start="1" value={amount} onChange={(e) => amountChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

        </div>

            
            <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
                AI Token <p className='text-sm text-red-500 underline'>3% Fee Already Deducted</p>
            </label>
            <input type="number" value={exchangeAmount} disabled className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

            </div>
         

            <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                OTP
            </label>
            <input value={otpValue} onChange={(e) => setOtpValue(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

            </div>

            <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
            Your Wallet Address
            </label>
            <input value={wallet_address} onChange={(e) => {setWalletAddress(e.target.value)}} placeholder='Wallet Address' className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

            </div>
           
            {/* <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                {depositLabel[ modalOTP["from"]]}
            </label>
            <input value={reference} onChange={(e) => handleReference(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

            </div> */}

        <br />

        {/* Navigation Buttons */}
                {/* Navigation Buttons */}
                <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {submitExchangeForm()}}>Next</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(7)}}>Back</Button>
        </div>
            
        </div>}


    </div>

  )
}

ExchangeForm.propTypes = {
    changeSelectedFromCurrency: PropTypes.func,
    changeStepProcess:PropTypes.func,
    selectedCurrency:PropTypes.any,
    selectedCurrencyDisplay:PropTypes.any,
}

export default ExchangeForm