import React from 'react'
import {Page, Text, Font, Image, View, Document, StyleSheet, PageHeader } from '@react-pdf/renderer';

import c1Logo from '../../assets/uploads/logo/c1logo.png'

function PDFTransactions({title, name, info, usdtProductTotal=0, btcProductTotal=0, userid, date, totalUSDT, totalBTC, totalZAR, idnumber, applyFilter, dateTo, dateFrom, currency, countryCurrencyDisplay, exchangeRates=null}) {

  // const currency = {
    //     '1': 'USDT',
    //     '2': 'ZAR',
    //     '3': 'BTC'
  // }

  const status = {
        '1': 'Approved',
        '2': 'Declined',
        '0': 'Pending'
  }

  Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
      
  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      //paddingBottom: 65,
      paddingHorizontal: 35,
    },
    image: {
      marginVertical: 10,
      height: "60px",
      width: "60px",
    },
    title: {
      fontSize: 20,
      textAlign: 'left',
      fontFamily: 'Oswald'
    },
    horizontalRule: {
      borderBottomWidth: 1,
      borderBottomColor: '#7bc2d8',
      marginVertical: 10, // Adjust as needed
    },
    inlineBlock: {
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
    },
    author: {
      fontSize: 18,
      textAlign: 'center',
      marginTop: 10,
      fontFamily: 'Oswald'
    },
    blockAuthor: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 15,
      marginBottom: 10,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    inlineBlock2: {
      backgroundColor: "#AACFDB",
      color: "white",
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
      borderTop: "0.5px solid #d0d6d9",
      borderLeft: "0.5px solid #d0d6d9",
      borderRight: "0.5px solid #d0d6d9",
      width: "100%", // Fixed width for the table
    },
    inlineBlock3: {
      color: "black",
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
      borderLeft: "0.5px solid #d0d6d9",
      borderRight: "0.5px solid #d0d6d9",
      borderBottom: "0.5px solid #d0d6d9",
      width: "100%", // Fixed width for the table
    },
    inlineBlock4: {
      color: "black",
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
      width: "100%",
      borderTop: "0.2px solid #d0d6d9",
      borderBottom: "0.2px solid #d0d6d9",
    },
    tableCell: {
      flex: 1,
      padding: "5px",
      textAlign: "center",
      borderLeft: "0.5px solid #d0d6d9", // Border for each cell
    },
    totals: {
      marginTop: "15px",
      fontSize: 12,
      border: "0.5px solid #d0d6d9",
      backgroundColor: "#AACFDB",
      color: "white",
      padding: "5px",
      textAlign: "center"
    },
  });



  const totalAmountUSDT = info.reduce((total, transaction) => {
    if (
      transaction.currency === "1" &&
      transaction.status === "1" &&
      transaction.tran_cat === "1"
    ) {
    return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalAmountZAR = info.reduce((total, transaction) => {
    if (
      transaction.currency === "2" &&
      transaction.status === "1" &&
      transaction.tran_cat === "1"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalAmountBTC = info.reduce((total, transaction) => {
    if (
      transaction.currency === "3" &&
      transaction.status === "1" &&
      transaction.tran_cat === "1"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

      
  const totalAmountUSDTInterest = info.reduce((total, transaction) => {
    if (
      transaction.currency === "1" &&
      transaction.status === "1" &&
      transaction.tran_cat === "4"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalAmountZARInterest = info.reduce((total, transaction) => {
    if (
      transaction.currency === "2" &&
      transaction.status === "1" &&
      transaction.tran_cat === "4"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalAmountBTCInterest = info.reduce((total, transaction) => {
    if (
      transaction.currency === "3" &&
      transaction.status === "1" &&
      transaction.tran_cat === "4"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  var haveUSDT = false;
  var haveZAR = false;
  var haveBTC = false;

  const checkAll = info.reduce((total, transaction) => {
    if (
      transaction.currency === "1" &&
      transaction.status === "1"
    ) {
      haveUSDT = true;
    }

    if (
      transaction.currency === "2" &&
      transaction.status === "1"
    ) {
      haveZAR = true;
    }

    if (
      transaction.currency === "3" &&
      transaction.status === "1"
    ) {
      haveBTC = true;
    }
       
  }, false);

  function truncateDecimals(number, decimalPlaces) {
    const multiplier = 10 ** decimalPlaces;
    return Math.floor(number * multiplier) / multiplier;
  }

  const Header = () => (
    <View style={{ marginBottom: 10 }}>
      <Image style={styles.image} src={c1Logo} />
      <Text style={styles.title}>C1 Wealth</Text>
      <View style={styles.horizontalRule} />
    </View>
  );

  const Footer = () => (
    <View fixed style={{ position: 'absolute', bottom: 25, left: 0, right: 0, textAlign: 'center', fontSize: 9 }}>
      {/* <Text style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>
        Page {pageNumber} of {totalPages}
      </Text> */}
      <Text
      style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )}
        fixed
      />
      <View style={{ borderBottomWidth: 1, borderBottomColor: '#7bc2d8', marginVertical: 10 }} />
      <Text style={{ fontSize: 9, textAlign: 'center' }}>
        C1 Wealth Management (Pty) Ltd, Company Registration: 2022/837320/07
      </Text>
      <Text style={{ fontSize: 9, textAlign: 'center' }}>
        Block C, The Old Tannery, Hermon Road, Wellington, 7654
      </Text>
    </View>
  );   

  const transactionsPerPage = 15; // Adjust the number of transactions per page as needed

  // Function to split transactions into chunks
  const chunkTransactions = (transactions, size) => {
    // Filter transactions to include only those with currency USDT
    const filteredTransactions = transactions.filter(transaction => currency[transaction.currency] === 'USDT');
    
    // Chunk the filtered transactions
    const result = [];
    for (let i = 0; i < filteredTransactions.length; i += size) {
      result.push(filteredTransactions.slice(i, i + size));
    }
    return result;
  };
  
  const chunkedTransactions = chunkTransactions(info, transactionsPerPage);

  const renderTransactionChunk = (transactions) => (
    <View>

      <Text style={styles.blockAuthor}>USDT</Text>
          <View style={styles.inlineBlock2}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >Date</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Name</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text>Description</Text>
              </View>
              <View style={{ flex: 2, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Transaction ID</Text>
              </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Amount</Text>
              </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Status</Text>
              </View>
          </View>
      {/* Render transactions here */}
      {haveUSDT ?
            transactions.map((transaction,key) => (
              currency[transaction.currency] === 'USDT'?
        <View key={key}>
          currency[transaction.currency] === 'USDT'?
              <View key={key} style={styles.inlineBlock3}  wrap={false} >
        
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text >{transaction.status === '0' ? transaction.createdAt : transaction.updatedAt}</Text>
                </View>

                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text >{name}</Text>
                </View>

                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                  <Text >{transaction.tran_type}</Text>
                </View>

                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                  <Text >{transaction.trans_id}</Text>
                </View>

                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                  <Text >{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) :  currency[transaction.currency] + truncateDecimals(transaction.amount,2)}</Text>
                </View>

                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9"  }}>
                  <Text >{status[transaction.status]}</Text>
                </View>
                
                {/* </View> */}
        
              </View>
        </View>
         : ''
         // : 
         //   ''
       ))
       :
       <View style={styles.inlineBlock3}  wrap={false} >
         <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
           <Text >No record found!</Text>
         </View>
       </View>
       }
      )
    </View>
  );

  const renderPages = () => (
    chunkedTransactions.map((chunk, index) => (
      <Page key={index} style={styles.body}>
                <Header />
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text >{name}</Text>
            <Text style={{ fontWeight: "bold" }}>Customer ID: {userid}</Text>
            <Text style={{ fontWeight: "bold" }}>ID / Passport: {idnumber}</Text>
          </View>
          <View style={{ flex: 1, textAlign: "right" }}>
            <Text style={{ fontWeight: "bold" }}>Date: {date}</Text>
          </View>
        </View>
        <Text style={styles.author}>{title}</Text>
        {/* Render other content */}
        {renderTransactionChunk(chunk)}
        <Footer />
      </Page>
    ))
  );

  const totalOMCAmountUSDT = info.reduce((total, transaction) => {
    if (
      transaction.currency === "1" &&
      transaction.status === "1" &&
      transaction.tran_cat === "18"
    ) {
      console.log("OMC Total: ", total + parseFloat(transaction.amount))
    return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalOMCAmountZAR = info.reduce((total, transaction) => {
    if (
      transaction.currency === "2" &&
      transaction.status === "1" &&
      transaction.tran_cat === "18"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalOMCAmountBTC = info.reduce((total, transaction) => {
    if (
      transaction.currency === "3" &&
      transaction.status === "1" &&
      transaction.tran_cat === "18"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalCapitalAmountUSDT = info.reduce((total, transaction) => {
    if (
      transaction.currency === "1" &&
      transaction.status === "1" &&
      transaction.tran_cat === "19"
    ) {
    return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalCapitalAmountZAR = info.reduce((total, transaction) => {
    if (
      transaction.currency === "2" &&
      transaction.status === "1" &&
      transaction.tran_cat === "19"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const totalCapitalAmountBTC = info.reduce((total, transaction) => {
    if (
      transaction.currency === "3" &&
      transaction.status === "1" &&
      transaction.tran_cat === "19"
    ) {
      return total + parseFloat(transaction.amount);
    }
    return total;
  }, 0);

  const countAvailableInExchangeAmount = async (amount, exchangeCurrency) => {
    let displayAmount = '0.00';
    console.log("We are going to return: ", displayAmount)
    return displayAmount;
    console.log(displayAmount);
    if (!exchangeRates)
        return displayAmount = '---';

    let exchangeRate = await exchangeRates(exchangeCurrency); // Wait for the Promise to resolve
    console.log("Exchange Rate is: ", exchangeRate);

    let availableAmount = amount * exchangeRate;

    return availableAmount;
}

console.log("Exchange Rate is: ", exchangeRates);

  return (

    <Document>
      
 
      {renderPages()}
     
      <Page style={styles.body}>  
        <Header />
          <View>
            <Text style={styles.blockAuthor}>BTC</Text>
            <View style={styles.inlineBlock2}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >Date</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Name</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Description</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Transaction ID</Text>
              </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Amount</Text>
              </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Status</Text>
              </View>
            </View>
        
            {haveBTC? 
              info.map((transaction,key) => (
                currency[transaction.currency] === 'BTC'?
                <View key={key} style={styles.inlineBlock3}  wrap={false} >
                  {/* {currency[transaction.currency] === 'USDT'? 
                  <View > */}
                    <View style={{ flex: 1, padding: "5px", textAlign: "center"}}>
                      <Text >{transaction.status === '0' ? transaction.createdAt : transaction.updatedAt}</Text>
                    </View>

                    <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                      <Text >{name}</Text>
                    </View>

                    <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                      <Text >{transaction.tran_type}</Text>
                    </View>

                    <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                      <Text >{transaction.trans_id}</Text>
                    </View>

                    <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                      <Text >{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) :  currency[transaction.currency] + truncateDecimals(transaction.amount, 2)}</Text>
                    </View>

                      <View style={[styles.tableCell, styles.borderLeft]}>
                        <Text >{status[transaction.status]}</Text>
                      </View>
                  {/* </View> */}
                </View>
                // : ''
                : 
                ''
              ))
              :
              <View style={styles.inlineBlock3}  wrap={false} >
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                  <Text >No record found!</Text>
                </View>
              </View>
            }
          </View>
        <Footer />
      </Page>


      <Page style={styles.body}>
        <Header />
          <View>
            <Text style={styles.blockAuthor}>{countryCurrencyDisplay}</Text>
            <View style={styles.inlineBlock2}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >Date</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                <Text >Name</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Description</Text>
              </View>
              <View style={{ flex: 2, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Transaction ID</Text>
              </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Amount</Text>
              </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                <Text >Status</Text>
              </View>
            </View>
          
            {haveZAR ?
              info.map((transaction, key) => (
                currency[transaction.currency] === countryCurrencyDisplay ?
                <View key={key} style={styles.inlineBlock3} wrap={false}>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                    <Text>{transaction.status === '0' ? transaction.createdAt : transaction.updatedAt}</Text>
                  </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                    <Text>{name}</Text>
                  </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                    <Text>{transaction.tran_type}</Text>
                  </View>
                  <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                    <Text>{transaction.trans_id}</Text>
                  </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                    <Text>{transaction.currency === '3' ? currency[transaction.currency] + truncateDecimals(transaction.amount, 5) : currency[transaction.currency] + truncateDecimals(transaction.amount, 2)}</Text>
                  </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
                    <Text>{status[transaction.status]}</Text>
                  </View>
                </View>
                :
                ''
              ))
              :
              <View style={styles.inlineBlock3}  wrap={false} >
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9"  }}>
                  <Text >No record found!</Text>
                </View>
              </View>
            }
            
          </View>
        <Footer /> 
      </Page>


      <Page style={styles.body}>
        <Header />
        <Text style={styles.author}>Totals</Text>
        <View>

          <Text style={styles.totals}>
            Total In Withdrawable Wallet:
          </Text>
          <View style={styles.inlineBlock4} wrap={false}>
                  
          <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9" }}>
    <Text >
        USDT {totalUSDT} (est. {countryCurrencyDisplay}{exchangeRates ? parseFloat(exchangeRates['12'] * totalUSDT).toFixed(2) : '---'})
    </Text>
</View>

                  
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9",  }}>
              <Text >{countryCurrencyDisplay} {totalZAR}</Text>
            </View>

            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9",  }}>
              <Text >BTC {totalBTC}</Text>
            </View>

          </View>
        

          <View>
            <Text style={styles.totals}>
              Total Deposit Amount:
            </Text>
            <View style={styles.inlineBlock4} wrap={false}>
              
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
                <Text >USDT {totalAmountUSDT}</Text>
              </View>
                    
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
                <Text >{countryCurrencyDisplay} {totalAmountZAR}</Text>
              </View>

              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9", }}>
                <Text >BTC {totalAmountBTC}</Text>
              </View>

            </View>
          </View>

          <View>
            <Text style={styles.totals}>
              Total Proceeds Earned:
            </Text>
            <View style={styles.inlineBlock4} wrap={false}>
                  
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
                <Text >USDT {truncateDecimals(totalAmountUSDTInterest, 2)} </Text>
              </View>
                  
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9", borderLeft: "0.2px solid #d0d6d9", }}>
                <Text >{countryCurrencyDisplay} {truncateDecimals(totalAmountZARInterest, 2)}</Text>
              </View>

              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9", }}>
                <Text >BTC {totalAmountBTCInterest}</Text>
              </View>

            </View>
          </View>

          <View>
            <Text style={styles.totals}>
              Total In Products:
            </Text>
            <View style={styles.inlineBlock4} wrap={false}>
                  
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
                <Text >USDT {usdtProductTotal}  (est. {countryCurrencyDisplay}{exchangeRates ? parseFloat(exchangeRates['12'] * usdtProductTotal).toFixed(2) : '---'})</Text>
              </View>

              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9", }}>
                <Text >BTC {btcProductTotal}</Text>
              </View>

            </View>
          </View>

          {parseFloat(totalOMCAmountUSDT) > 0 || parseFloat(totalOMCAmountZAR) > 0 || parseFloat(totalOMCAmountBTC) > 0 ?
  <View>
    <Text style={styles.totals}>
      Total Outstanding OMC Amount:
    </Text>
    <View style={styles.inlineBlock4} wrap={false}>
      
      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
        <Text>USDT {totalOMCAmountUSDT}</Text>
      </View>
            
      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
        <Text>{countryCurrencyDisplay} {totalOMCAmountZAR}</Text>
      </View>

      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9", }}>
        <Text>BTC {totalOMCAmountBTC}</Text>
      </View>

    </View>
  </View>
  : null}

{parseFloat(totalCapitalAmountUSDT) > 0 || parseFloat(totalCapitalAmountZAR) > 0 || parseFloat(totalCapitalAmountBTC) > 0 ?
  <View>
    <Text style={styles.totals}>
      Total Capital Amount:
    </Text>
    <View style={styles.inlineBlock4} wrap={false}>
      
      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
        <Text>USDT {totalCapitalAmountUSDT}</Text>
      </View>
            
      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", }}>
        <Text>{countryCurrencyDisplay} {totalCapitalAmountZAR}</Text>
      </View>

      <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", borderLeft: "0.2px solid #d0d6d9", borderRight: "0.2px solid #d0d6d9", }}>
        <Text>BTC {totalCapitalAmountBTC}</Text>
      </View>

    </View>
  </View>
  : null}

        </View>

        <Footer />

      </Page>

    </Document>

  )
}

export default PDFTransactions