import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../../../UserContext";
import axios from 'axios';
import PDFProducts from "../../PDF/PDFProducts";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFProductsBreakdown from "../../PDF/PDFProductsBreakdown";

function ProductsReport() {

  let balanceObj = {
    'USDT': 0,
    'ZAR': 0,
    'BTC': 0
  }

  const [selectedReport, setSelectedReport] = useState('all');
  const { auth, user } = React.useContext(UserContext);
  const [name, setName] = useState('');
  const [IDNumber, setIDNumber] = useState('');
  const [userid, setUserid] = useState('');
  const [useDate, setUseDate] = useState('');
  const [totalZAR, setTotalZAR] = useState(0);
  const [totalUSDT, setTotalUSDT] = useState(0);
  const [totalBTC, setTotalBTC] = useState(0);
  const [productData, setProductData] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [usdtProductAmount, setUsdtProductAmount] = useState(0)
  const [btcProductAmount, setBTCProductAmount] = useState(0)
  const [countryCurrencyDisplay, setCountryDisplay] = useState('ZAR')
  const[exchangeRates, setExchangeRates] =  React.useState(null);
  const [currencyDisplay, setCurrencyDisplay] = useState({
    '1': 'USDT',
    '2': 'ZAR',
    '3': 'BTC',
  });
  const [loadingPDF, setLoadingPDF] = useState(true); // State for loading PDF

  const handleRadioButtonChange = (value) => {
    setSelectedReport(value);
    console.log(value);
    getUserSelectedData(value);
  };

  useEffect(() => {
    if (auth && user) {
      getUserDetails(user.user.userid);
      getExchangeRates();
      setName(user.user.name + ' ' + user.user.lastname);
      setUserid(user.user.userid);
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let newstring = '';
      if (month < 10) newstring = '0' + month;
      else newstring = month;
      setUseDate(year + '-' + newstring + '-' + date);
      getUserBalances(user.user.userid);
      getUserProductData(user.user.userid);
    }
  }, [auth]);

  useEffect(() => {
    if (selectedProductData.length > 0) {
      setLoadingPDF(false);
    } else {
      setLoadingPDF(true);
    }
  }, [selectedProductData]);

  const getUserDetails = async (userid) => {
    var userObj = { userid: userid };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`, userObj).then(async (res) => {
      if (res.data.status === 200) {
        var mainString = res.data.info.country;
        setIDNumber(res.data.info.id_num);
        if (mainString && mainString.includes("Namibia")) {
          setCurrencyDisplay({
            '1': 'USDT',
            '2': 'NAD',
            '3': 'BTC',
          });
          setCountryDisplay('NAD')
        }
      }
    });
  };

  const getUserBalances = async (userid) => {
    var userObj = { userid: userid };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`, userObj).then(async (res) => {
      if (res.data.status === 200) {
        setTotalZAR(res.data.currencyInfo[1].balance);
        balanceObj["ZAR"] = res.data.currencyInfo[1].balance;
        setTotalUSDT(res.data.currencyInfo[0].balance);
        balanceObj["USDT"] = res.data.currencyInfo[0].balance;
        setTotalBTC(res.data.currencyInfo[2].balance);
        balanceObj["BTC"] = res.data.currencyInfo[2].balance;
      }
    });

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sum-of-user-products`,userObj).then((res)=>{
      if(res.data.status == 200)
      {
        setUsdtProductAmount(res.data.usdt)
        setBTCProductAmount(res.data.btc)
      }
    })
    return true;
  };

  const getUserProductData = async (userid) => {
    var userObj = { userid: userid };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/all-user-open-products`, userObj).then(async (res) => {
      if (res.data.status === 200) {
        console.log("Product Data: ", res.data.total);
        setProductData(res.data.total);
        setSelectedProductData(res.data.total);
      }
    });
    return true;
  };

  const getUserSelectedData = async (selectedOption) => {
    if (selectedOption === "current") {
      const filteredData = productData.filter(item => item.status === "1");
      console.log(filteredData);
      setSelectedProductData(filteredData);
    } else {
      setSelectedProductData(productData);
    }
    
  };

  const getExchangeRates = async () => {
    await axios.get(`${process.env.REACT_APP_URL}exchange-rates`,{}).then(async (res)=>{
        if(res.data.status == 200)
        {
          console.log(res.data.rates)
          setExchangeRates(res.data.rates)
        }
        
    })
};

  

  return (
    
    <div>
      <div>My <span className="primary-color">Products <FontAwesomeIcon className="pl-2" icon={faCaretDown} size="xl" /></span></div>
      <div>

        <div className="mt-4 mb-4">
          <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={selectedReport === 'all'} className="btn-check" name="vbtn-radio" value="all" id="allOption" />
          <label style={{ borderRadius: "70px", padding: "5px 10px", marginBottom: "20px" }} className={'mr-8' + (selectedReport === 'all' ? ' c1-primary-btn-active' : '')} htmlFor="allOption">All</label>

          <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={selectedReport === 'current'} className="btn-check" name="vbtn-radio" value="current" id="currentOption" />
          <label style={{ borderRadius: "70px", padding: "5px 10px", marginBottom: "20px" }} className={'mr-8' + (selectedReport === 'current' ? ' c1-primary-btn-active' : '')} htmlFor="currentOption">Current</label>
        </div>
      </div>

    <div className=" mt-2 mb-3">
      <h3 className="mt-2 mb-2">Product Statement</h3>
      <PDFDownloadLink
        document={<PDFProducts title="Product Statement" name={name} currency={currencyDisplay} idnumber={IDNumber} info={selectedProductData} userid={userid} date={useDate} />}
        fileName={"Products-" + useDate}
      >
        {({ loading }) => loading || loadingPDF ? "Loading..." : <button className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
          Download PDF Statement
        </button>}
      </PDFDownloadLink>
      </div>
      <h3>Product Summary and Proceeds Breakdown</h3>

      <div className=" mt-2 mb-3">
      <PDFDownloadLink
        document={
        <PDFProductsBreakdown 
          title="Product Summary and Proceeds Breakdown" 
          name={name} 
          currency={currencyDisplay} 
          idnumber={IDNumber} 
          info={selectedProductData} 
          userid={userid} 
          date={useDate} 
          usdtProductTotal={usdtProductAmount} 
          btcProductTotal={btcProductAmount}
          totalUSDT={totalUSDT}
          totalBTC={totalBTC}
          totalZAR={totalZAR}
          countryCurrencyDisplay={countryCurrencyDisplay}
          exchangeRates={exchangeRates}
        />}
        fileName={"Products-" + useDate}
      >
        {({ loading }) => loading || loadingPDF ? "Loading..." : <button className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
          Download Breakdown
        </button>}
      </PDFDownloadLink>
      </div>

    </div>
  );
}

export default ProductsReport;
