import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({userid}) {

    const [loanInfo, setLoanInfo] = useState(null)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };
    
        fetchData();
    }, [userid]); 

    const getUserLoanOptions = async() => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-loan-info",userObj).then(async (res)=>{
        
            if(res.data.status === 200)
            {
                setLoanInfo(res.data.data)
            }
        });
    }
  return (
    <div className="container-md px-4 pl-6">
        <div className="w-full">
        
            <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                <div className="mb-4">
                    <h1 className="title text-white text-center mb-2">Loan Info</h1>

                    {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                    <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">
                        <h2 className="text-2xl font-semibold text-gray-400 mb-4">Introducing C1 Wealth Loans' New Feature: Multiply Your Loan!</h2>
                        <p className="mb-4">We're excited to bring you a brand new way to enhance your financial power with C1 Wealth Loans. Now, you can get more from your loan with our Loan to Value (LTV) product. It's straightforward and designed to boost your borrowing capacity.</p>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">How Does It Work?</h3>
                        <ul className="list-disc pl-5 mb-4">
                            <li><strong>Multiply Your Loan:</strong> Choose to increase your loan amount by 3 to 5 times. This means if you start with a deposit, we'll boost it, giving you more financial flexibility.</li>
                            <li><strong>70% Goes Further:</strong> When you deposit, 70% of that amount automatically goes into our special product, where it's multiplied according to your chosen rate.</li>
                            <li><strong>Payback with Ease:</strong> The best part? The product itself helps pay off your loan every month using the proceeds. So, if you deposit USDT 10,000, you could see it grow to USDT 30,000 over 5 years, all while it helps in paying off the loan.</li>
                        </ul>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">Simple and Effective</h3>
                        <p className="mb-4">Our goal is to make your financial growth easy. With this new feature, you're not just getting a loan; you're investing in an opportunity to expand your financial horizon without the extra hassle.</p>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">Ready to Multiply Your Loan?</h3>
                        <p>Get started today and see how your deposit can do more for you. With C1 Wealth Loans, your financial goals are closer than you think.</p>
                    </div>
                    : ''}
                    
                    <div>
                        <div className="p-2">
                            <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                        </div>  
                        <div className="p-2">
                            <span >Future Payout Amount (USDT): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInUSDT : "N/A"}</span>
                        </div>  
                        <div className="p-2">
                            <span >Future Payout Amount (ZAR/NAD): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInRand : "N/A"}</span>
                        </div>  
                        

                        <div className="flex items-center justify-between">
                            <a href="./my-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                View My Loan Products
                            </a>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
  )
}

export default LoanHistory