import React, { useState, useEffect } from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({ userid }) {

    const [loanInfo, setLoanInfo] = useState(null)

    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };

        fetchData();
    }, [userid]);

    const getUserLoanOptions = async () => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-loan-ltv-info", userObj).then(async (res) => {
          
            if (res.data.status === 200) {
                setLoanInfo(res.data.data)
            }
        });
    }
    return (
        <div id="laon-info-text" className="container-md px-4 pl-6">
            <div className="w-full">

                <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                    <div className="mb-4">
                        <h1 className="title text-white text-center mb-2">LTV Loan</h1>

                        {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                            <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">

                                <p className="mb-4">
                                    LTV Loan - your pathway to financial flexibility with cryptocurrency! Secure a loan in USDT using your BTC and ETH
                                    holdings while retaining ownership. With simplified processes and flexible terms, gain immediate liquidity without
                                    sacrificing future growth. Join us today for seamless access to funds and control over your crypto assets.
                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Use Your Crypto as Collateral</h3>
                                <p className="pl-5 mb-4">
                                    Easily leverage BTC and ETH to secure a
                                    loan, accessing USDT without selling your
                                    valuable assets.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Flexible Repayment Terms</h3>
                                <p className="pl-5 mb-4">
                                    Choose a 60-month term with assistance
                                    in loan repayment, showcasing our
                                    commitment to your financial well-being.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Get USDT, Maintain Your Crypto</h3>
                                <p className="pl-5 mb-4">
                                    Receive USDT while your BTC or ETH
                                    potentially appreciates in value, ensuring
                                    liquidity without sacrificing future growth.

                                </p>

                                <h3 className="text-xl font-semibold text-gray-400 mb-3">Long-Term Support</h3>
                                <p className="pl-5 mb-4">
                                    Opting for a 60-month repayment term
                                    ensures ongoing assistance in managing
                                    and paying off your loan, providing
                                    support throughout the process.

                                </p>
                            </div>
                            : ''}

                        <div>
                            <div className="p-2">
                                <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                            </div>
                            <div className="p-2">
                                <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                            </div>


                            <div className="flex items-center justify-between">
                                <a href="./my-ltv-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    View My Loan Products
                                </a>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default LoanHistory