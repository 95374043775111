import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function Welcome({changeStepProcess, closeProductModal}) {
  return (
    <div>
        <h2 className="text-center">AI TOKEN</h2><br />

        <h5 className="text-center">Powering Your Future with Gold-Backed Asset Growth</h5><br />

        <p>Introducing Asset Index Token, the innovative decentralized digital asset offered by the C1 Group. AI Token is backed by securely vaulted physical gold and linked to the performance of an Index, providing stability, growth, predictability, and security for holders.</p>

        <p>With AI Token, you can benefit from the growth of the Index while enjoying fast, efficient, and low-cost transactions on the platform. It promotes financial inclusion and accessibility for all users.</p>

        <p>AI Token is available on C1 Group platforms and can be stored in your Exodus wallet. It will be launched on other exchanges in 2024. Venture Capitalists, C1 Group members, and the public will have opportunities to participate in the project.</p>

        <p>Unique to AI Token is its physical gold backing, with 25% of each dollar spent on purchases allocated towards buying securely vaulted gold. This provides an extra layer of stability and security for holders, acting as a safety net during crypto market fluctuations or unforeseen events.</p>

        <p>Experience the innovative and secure AI Token, backed by physical gold, for stable and growth-oriented digital asset investment.</p>

        {/* Navigation Buttons */}
        <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(3)}}>I have an AIT Wallet</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(2)}}>I do not have an AIT Wallet</Button>

          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeProductModal}>Close</Button>
        </div>
            
    </div>
  )
}

Welcome.propTypes = {
    closeProductModal: PropTypes.func,
    changeStepProcess:PropTypes.func,
}

export default Welcome