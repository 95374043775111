import { Button } from '@mui/material';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewDeposit({ changeStepProcess, closeProductModal }) {
  const btcWalletRef = useRef(null);
  const usdtWalletRef = useRef(null);

  const usdtWallet = "TNJgRTztT2AT9e361UuaJZ35T5aCaYqMh3";
  const btcWallet = "bc1qhaldluf6qrcm72aejxyajaf88ngnu0zl4qcvnp";

  const handleCopy = () => {
    navigator.clipboard.writeText(usdtWallet)
      .then(() => {

        // You can show a success message or perform any additional actions here

        toast.success('Copied Text To Clipboard', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
      })
      .catch((error) => {
        console.error('Failed to copy wallet address:', error);
        // You can show an error message or handle the error in any desired way
      });
  };

  const handleCopyBTC = () => {
 
    navigator.clipboard.writeText(btcWallet)
      .then(() => {
   
        toast.success('Copied Text To Clipboard', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
      })
      .catch((error) => {
        console.error('Failed to copy wallet address:', error);
        // You can show an error message or handle the error in any desired way
      });
  };

  

  return (
    <div>
      <h2 className="text-center">New Deposit</h2>
      <br />

      <p>Select one of the following currencies:</p>
      <br />

      <div className="grid grid-cols-2 gap-2">
        <div>
          <h5 className='text-center'><u>BTC</u></h5>

          <br />
          <div className='mt-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src="https://aitokensa.co.za/wp-content/uploads/elementor/thumbs/BTC-q96ci2qckewsyh0b7sawbdwgxkw3m33ozvvevih20k.png" alt="btc-wallet" />
            <br />
            <img src="https://aitokensa.co.za/wp-content/uploads/elementor/thumbs/btc-1-q96cu41zaw6f8pomceh2uxebtjrxub3o797f9h3sd8.png" alt="btc-wallet" />
          </div>

          <div className='mt-2 w-48' ref={btcWalletRef}>
            <p className="text-sm break-words">{btcWallet}</p>
            <button style={{
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff",
            padding: "6px"
          }} className="mt-2 c1-primary-btn" onClick={() => handleCopyBTC()}>Copy</button>
          </div>
        </div>

        <div>
          <h5 className='text-center'><u>USDT</u></h5>
          <br />
          <div className='mt-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src="https://aitokensa.co.za/wp-content/uploads/elementor/thumbs/TETHER-q96ckmuj66ewk9ai9w4c3qnn5ca2kf9e0jszwmoh2s.png" alt="usdt-wallet" />
            <br />
            <img src="https://aitokensa.co.za/wp-content/uploads/elementor/thumbs/tether-1-q96cyd9o8w05t5i2hqp9ncqgncsroyzx4bjkhksm70.png" alt="usdt-qr" />
          </div>

          <div className='mt-2 w-48' ref={usdtWalletRef}>
            <p className="text-sm break-words">{usdtWallet}</p>
            <button style={{
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff",
            padding: "6px"
          }} className="mt-2 c1-primary-btn" onClick={() => handleCopy()}>Copy</button>
          </div>
        </div>
      </div>

      <br />

      {/* Navigation Buttons */}
      <div className='mt-3'>
        <Button
          style={{
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"
          }}
          className='c1-primary-btn'
          onClick={() => { changeStepProcess(5) }}
        >
          I have the TXID
        </Button>

        <Button
          style={{
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            float: "right",
            color: "#fff"
          }}
          className='c1-primary-btn'
          onClick={() => { changeStepProcess(3) }}
        >
          Back
        </Button>
      </div>

    </div>
  );
}

NewDeposit.propTypes = {
  closeProductModal: PropTypes.func,
  changeStepProcess: PropTypes.func,
};

export default NewDeposit;
