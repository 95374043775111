import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CopyToClipboardButton from "../../../../components/Buttons/CopyToClipboardButton";

import X3Image from './Images/X3.png'
import X5Image from './Images/X5.png'

function LoanCalculator({userid}) {

    const [loanAmount, setLoanAmount] = useState(0)
    const [termOption, setTermOption] = useState(null)
    const [multiplierOption, setMultiplierOption] = useState(null)

    const [userAmount, setUserAmount] = useState(0)
    const [payoutAmount, setPayoutAmount] = useState(0)
    const [poolBalanceAmount, setpoolBalanceAmount] = useState(null)
    const [otpPin, setOTPPin] = useState(null)

    
    const [showSpinner, setShowSpinner] = useState(false)
    const [mainLoader, setMainLoader] = useState(true)

    const [currency, setCurrency] = useState(null)

    const [step, setStep] = useState(1)
    const [loanSettings, setLoanSettings] = useState({})

    // var loanSettings = {
    //     termOptions: {
    //         5: 60,
    //         10: 120
    //     },
    //     multiplierOptions: {
    //         5: 3,
    //         10: 6
    //     },
    //     currencyOptions: {
    //         1: "USDT",
    //         2: "ZAR/NAD"
    //     },
    //     yearlyInterest: 0,
    //     monthlyProceeds: 0,
    //     percentageToLoan: 70,
    //     percentageToPool: 20,
    //     percentageToCommision: 10
    // }

    React.useEffect(() => {
        const fetchData = async () => {
            await getLoanOptions();
        };
    
        fetchData();
    }, [userid]); 

    const getLoanOptions = async() => {
        var loanObj = {
            loanName: 'multiplier_loan'
        }
        await axios.post(process.env.REACT_APP_BASE + "api/loan-options",loanObj).then((res)=>{
            console.log("Loan Options is: ", res.data)
            if(res.data.status == 200)
            {
                var newLoanInfo = JSON.parse(res.data.loanInfo.loan_info);
                console.log(newLoanInfo)
                setLoanSettings(newLoanInfo);
                setMainLoader(false)
            }
            
        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelect = async(multiplier, term) => {
        setMultiplierOption(multiplier)
        setTermOption(term);
        console.log("userAmount: ", userAmount)
        await calculateProjectedAmount(multiplier, term, userAmount)
    };

    const calculateProjectedAmount = async(multiplier, term, amount) => {
        console.log(amount, multiplier, userAmount * multiplier)
        setPayoutAmount(amount * multiplier)

        var poolPercentage = (loanSettings.percentageToPool + loanSettings.percentageToCommision) / 100;
        var amountToPoolOriginal = amount * poolPercentage;
        setpoolBalanceAmount((amountToPoolOriginal) * multiplier)

        var loanPercentage = loanSettings.percentageToLoan / 100;
        var amountToLoanOriginal = amount * loanPercentage;
        setLoanAmount((amountToLoanOriginal) * multiplier)
    }

    const onInvestmentAmountChange = async(amount) => {
        setUserAmount(amount)
        await calculateProjectedAmount(multiplierOption, termOption, amount)
    }

    const nextStep = async() => {
        var newStep = step + 1;
      
        if(newStep === 2)
        {
            sendOTP();
        }
        setStep(step + 1)
    }

    const prevStep = async() => {
        if(step > 1)
            setStep(step - 1)
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
 
            if(res.data.status == 200)
            {
            
                toast.success('OTP Pin Successfully Sent!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

            }else{
            
                toast.error('Unable To send OTP!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
            
        })

    }

    const handleAccept = async () => {

        setShowSpinner(true)
    

        if(!otpPin)
        {
            toast.error('Please Enter Valid OTP Pin', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                setShowSpinner(false)

                return false;
        }
        

        if(!currency || currency === '')
        {
            toast.error('Please Select a Currency', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                setShowSpinner(false)

                return false;
        }
 

            var otpObj = {
                userid: userid,
                otp: otpPin
            }
            
            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
      
                if(res.data.status !== 200)
                {
                    
                    toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });

                        await sendOTP();

                        setShowSpinner(false)

                    return false;
                }else{
             
                
                    return true;
                }
            });

            if(!checkOtp)
                return false;

            // Let check their balance first
            var balanceObject = {
                userid: userid,
                currency: currency,
              }
          
              var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject).then(async res => {
          
                if(res.data.status === 200)
                {
                    if (parseFloat(res.data.total) < userAmount) {
                        toast.error('Insufficient Balance!', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                
                        setShowSpinner(false)
                
                        return false;
                      } else {
                        return true
                      }
                }else{
                    return false;
                }
               
          
              })
          
              if (!checkBalance)
                return false;
   
            // Create the loan
            // userid, term, multiplier, currency, userAmount, loanAmount, payoutAmount, poolBalanceAmount
            const loanTransactionObj = {
                userid: userid,
                term: loanSettings.termOptions[termOption],
                multiplier: multiplierOption,
                currency: currency,
                userAmount: userAmount,
                loanAmount: loanAmount,
                poolBalanceAmount: poolBalanceAmount,
                payoutAmount: payoutAmount
            }
            await axios.post(process.env.REACT_APP_BASE + "api/create-new-loan",loanTransactionObj).then(async (res)=>{
                console.log("Transaction is: ", res.data)
                if(res.data.status === 200)
                {
                 
                        toast.success('Product Loan Created Successfully!', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });

                        await saveUserActivity(userid, "User Added Loan Application")

                        setTimeout(() => {window.location.reload(); }, 2000);

                }else{

                    setShowSpinner(false)
                    toast.error('Unable To Create Product Sale!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                      });

                      return false;
                }
            });


        }

        const saveUserActivity = async(userid, activity) => {

            var activityData = {
                userid: userid,
                activity: activity
            }
    
    
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`,activityData).then(async (res)=>{
    
            })
          }
        

    return (
        <div className="container-md px-4 pl-6">
                    <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
            <div className="w-full">
                <form     className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box" 
                    onSubmit={(e) => { e.preventDefault(); }}>
                    <h1 className="title text-white text-center mb-2">Loan Calculator</h1>
                    {!mainLoader?
                    
                    step === 1 ?
                    <div>
                        <div className="mb-4">
                        
                            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                Investment Amount
                            </label>
                            <input type="number" step="0.01" min="0" value={userAmount} onChange={(e) => { onInvestmentAmountChange(e.target.value); }} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                        </div>

                        <div className="mb-4 mt-3">
                            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                Multiplier
                            </label>
                            <div className="flex items-center justify-between mb-4">
                            {
                                loanSettings.multiplierOptions && Object.entries(loanSettings.multiplierOptions).map(([key, value]) => (
                                    <div key={key} className="w-1/2 ml-1 mr-1" style={{ backgroundImage: value === 3 ? `url(${X3Image})` : value === 5 ? `url(${X5Image})` : 'none', backgroundSize: 'cover'  }}>
                                        <div className={`flex justify-center items-center h-32 bg-teal-500 rounded-lg shadow-md multiplier-box ${multiplierOption === value ? 'selected-box' : 'unselected-box'}`} onClick={() => { handleOptionSelect(value, key) }}>
                                            <div className="text-white text-center">
                                                <h2 className="text-xl font-bold">X{value}</h2>
                                                <p className="text-sm">{key} Years</p>
                                            </div>
                                            <div className="overlay" style={{ opacity: multiplierOption === value ? 0 : 0.8 }}></div>
                                        </div>
                                        
                                    </div>
                                ))
                            }
                            </div>
                        </div>

                        <div className="mb-4 mt-3">
                            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                Estimated Payout Amount <small>(After {termOption} Years)</small>
                            </label>
                            <input type="number" disabled step="0.01" min="0" value={payoutAmount} className="bg-gray-500 form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>

                        <div className="mb-4 mt-3">
                            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                Amount To Pool
                            </label>
                            <input type="number" disabled step="0.01" min="0" value={poolBalanceAmount} className="bg-gray-500 form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none  focus:shadow-outline" />
                        </div>

                        <div className="mb-4 mt-3">
                            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                Loan Amount To Product
                            </label>
                            <input type="number" disabled step="0.01" min="0" value={loanAmount} className="bg-gray-500 form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>

                        <div className="flex items-center justify-between">
                            <button onClick={() => {nextStep()}} className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                Invest Now
                            </button>
                        </div>
                    </div>
                    : step === 2 ?
                        <div>
                            <div className="mb-4">
                            
                            <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                                Please Select A Currency 
                            </label>
                            <select onChange={(e) => setCurrency(e.target.value)} value={currency}  className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline">
                                <option value="">Select Currency</option>
                                {loanSettings ? Object.entries(loanSettings.currencyOptions).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                )): ''}
                            </select>
                            </div>

                            <div className="mb-4">
                            
                            <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                                OTP PIN 
                            </label>
                            <input type="text" value={otpPin} onChange={(e) => {setOTPPin(e.target.value)}} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline form-no-background" />
                            </div>
                                
                            {/* {currency?
                            <div className="mb-4 mt-3">
                                <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                                    Please use one of the following payment details:
                                </label>
                                <div className="flex flex-col space-y-4 p-3 form-no-background">
                                    <ul>
                                        {currency === '2' ?
                                        <li>
                                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                                <li style={{listStyleType:"disc"}}>Account Holder: C1 Wealth Management (Pty) Ltd</li>
                                                <li style={{listStyleType:"disc"}}>Bank Name: Capitec Business</li>
                                                <li style={{listStyleType:"disc"}}>Account Type: Current Account</li>
                                                <li style={{listStyleType:"disc"}}>Account Number: 1051271495</li>
                                                <li style={{listStyleType:"disc"}}>Branch Code: 450105</li>
                                                <li style={{listStyleType:"disc"}}>Branch Name: Sandton</li>
                                                <li style={{listStyleType:"disc"}}>Swift Code: CABLZAJJ</li>
                                            </ul>
                                            OR 
                                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                                <li style={{listStyleType:"disc"}}>Account Holder: C1 Wealth Management (Pty) Ltd</li>
                                                <li style={{listStyleType:"disc"}}>Bank Name: Standard Bank</li>
                                                <li style={{listStyleType:"disc"}}>Account Type: BIZLAUNCH</li>
                                                <li style={{listStyleType:"disc"}}>Account Number: 10208612953</li>
                                                <li style={{listStyleType:"disc"}}>Branch Code: 000205</li>
                                                <li style={{listStyleType:"disc"}}>Branch Name: Johannesburg</li>
                                                <li style={{listStyleType:"disc"}}>Swift code: SBZA ZA JJ</li>
                                            </ul>
                                            OR
                                            <p className="text-red-500">(Namibia Only)</p>
                                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                                <li style={{listStyleType:"disc"}}>Account Holder: C ONE FINANCIAL SERVICES</li>
                                                <li style={{listStyleType:"disc"}}>Bank Name: FNB</li>
                                                <li style={{listStyleType:"disc"}}>Account Type: Enterprise Business Account</li>
                                                <li style={{listStyleType:"disc"}}>Account Number: 64282301123</li>
                                                <li style={{listStyleType:"disc"}}>Branch Code: 280172</li>
                                                <li style={{listStyleType:"disc"}}>Branch Name: Windhoek</li>
                                                <li style={{listStyleType:"disc"}}>Swift Code: FIRNNANX</li>
                                            </ul>
                                        
                                        </li>
                                        : currency === '1' ?
                                        <li>For USDT TRC-20 (TRON) payments please use the following wallet address:
                                            <ul className='ml-3' style={{ listStyleType: "disc", wordWrap: "break-word" }}>
                                                <li style={{listStyleType:"disc"}}>TMCcqUdmi6d5VnjHAM6Gr3AKibv5CSqSiE</li>
                                                <CopyToClipboardButton text="TMCcqUdmi6d5VnjHAM6Gr3AKibv5CSqSiE" tokenName={"TRC-20"} />
                                            </ul>
                                        </li>
                                        : ''}

                                    </ul>  
                                </div>
                            </div>
                            : ''} */}

                            {!showSpinner ?
                            <div className="flex items-center justify-between">
                                <button onClick={() => {handleAccept()}} className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    Invest Now
                                </button>
                                <Button style={{    
                                    background: "#040E18",
                                    borderRadius: "70px",
                                    border: "2px solid #fa0a0a",
                                    float: "right",
                                    color: "#fff"}} className='c1-primary-btn' onClick={prevStep}>Back</Button>
                            </div>
                            : <p>Loading...</p>
                             }
                        </div>        
                    : ''
                : <div class="loader-c1fs"></div>}
                </form>
            </div>
        </div>
    )
}

export default LoanCalculator