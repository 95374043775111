import React from "react";
import { Page, Text, Font, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import c1Logo from '../../assets/uploads/logo/c1logo.png';

function PDFProductsBreakdown({ title, name, info, userid, date, currency, idnumber, usdtProductTotal = 0, btcProductTotal = 0, totalUSDT = 0, totalBTC = 0, totalZAR = 0, countryCurrencyDisplay, exchangeRates = null }) {

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 20,
      textAlign: 'left',
      fontFamily: 'Oswald'
    },
    horizontalRule: {
      borderBottomWidth: 1,
      borderBottomColor: '#7bc2d8',
      marginVertical: 10,
    },
    author: {
      fontSize: 18,
      textAlign: 'center',
      marginTop: 10,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      height: "70px",
      width: "70px",
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    inlineBlock: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 14,
    },
    inlineBlock2: {
      backgroundColor: "#AACFDB",
      color: "white",
      display: "flex",
      flexDirection: "row",
      fontSize: 12,
    },
    inlineBlock3: {
      color: "black",
      borderTop: "0.1px solid #d0d6d9",
      display: "flex",
      flexDirection: "row",
      fontSize: 9,
    },
    tableBlock: {
      marginTop: "20px",
      borderTop: "0.8px solid #d0d6d9",
      borderLeft: "0.8px solid #d0d6d9",
      borderRight: "0.8px solid #d0d6d9",
      borderBottom: "0.8px solid #d0d6d9",
    },
    footer: {
      marginTop: 'auto',
      fontSize: 9,
      paddingHorizontal: 10,
    },
    footerText: {
      fontSize: 9,
      textAlign: 'center',
    },
  });

  const currencyMap = {
    '1': 'USDT',
    '2': countryCurrencyDisplay,
    '3': 'BTC'
  };

  // Helper function to calculate monthly totals and sort them
  const calculateMonthlyTotals = (info) => {
    const monthlyTotals = {};

    info.forEach(transaction => {
      transaction.interests.forEach(interest => {
        const dateObj = new Date(interest.date);
        const monthYear = dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

        const currencySymbol = currencyMap[transaction.currency] || 'unknown';

        if (!monthlyTotals[currencySymbol]) {
          monthlyTotals[currencySymbol] = {};
        }

        if (!monthlyTotals[currencySymbol][monthYear]) {
          monthlyTotals[currencySymbol][monthYear] = 0;
        }

        monthlyTotals[currencySymbol][monthYear] += parseFloat(interest.amount);
      });
    });

    // Sort the months correctly
    Object.keys(monthlyTotals).forEach(currency => {
      monthlyTotals[currency] = Object.fromEntries(
        Object.entries(monthlyTotals[currency]).sort((a, b) => new Date(a[0]) - new Date(b[0]))
      );
    });

    return monthlyTotals;
  };

  const monthlyTotals = calculateMonthlyTotals(info);

  const Footer = () => (
    <View style={styles.footer}>
      <Text
        style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )}
        fixed
      />
      <View style={styles.horizontalRule} />
      <Text style={styles.footerText}>C1 Wealth Management (Pty) Ltd, Company Registration: 2022/837320/07</Text>
      <Text style={styles.footerText}>Block C, The Old Tannery, Hermon Road, Wellington, 7654</Text>
    </View>
  );

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.image} src={c1Logo} />
        <Text style={styles.title}>C1 Wealth</Text>
        <View style={styles.horizontalRule} />
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text>{name}</Text>
            <Text style={{ fontWeight: "bold" }}>Customer ID: {userid}</Text>
            <Text style={{ fontWeight: "bold" }}>ID / Passport: {idnumber}</Text>
          </View>
          <View style={{ flex: 1, textAlign: "right" }}>
            <Text style={{ fontWeight: "bold" }}>Date: {date}</Text>
          </View>
        </View>
        <Text style={styles.author}>{title}</Text>

        {info.map((transaction, key) => {
          const totalGenerated = parseFloat(transaction.amount) + transaction.interests.reduce((acc, interest) => acc + parseFloat(interest.amount), 0);

          return (
            <View style={styles.tableBlock} key={key}>
              <View style={styles.inlineBlock2}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text>Date</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>Initial Amount</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>Monthly Proceeds</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>Total Generated</Text>
                </View>
              </View>

              <View style={styles.inlineBlock3} key={key} wrap={false}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text>{transaction.invest_date}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>{currencyMap[transaction.currency] + transaction.amount}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>
                    {transaction.interests.map(interest => (
                      <Text key={interest.id}>{currencyMap[transaction.currency] + interest.amount.toFixed(2).toString() + "\n"}</Text>
                    ))}
                  </Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>{currencyMap[transaction.currency] + totalGenerated.toFixed(2).toString()}</Text>
                </View>
              </View>
            </View>
          );
        })}

        <View style={styles.horizontalRule} />
        <Footer />
      </Page>
      <Page style={styles.body}>
        <Image style={styles.image} src={c1Logo} />
        <Text style={styles.title}>C1 Wealth</Text>
        <View style={styles.horizontalRule} />
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text>{name}</Text>
            <Text style={{ fontWeight: "bold" }}>Customer ID: {userid}</Text>
            <Text style={{ fontWeight: "bold" }}>ID / Passport: {idnumber}</Text>
          </View>
          <View style={{ flex: 1, textAlign: "right" }}>
            <Text style={{ fontWeight: "bold" }}>Date: {date}</Text>
          </View>
        </View>
        <Text style={styles.author}>{"Monthly Proceeds Summary"}</Text>

        {Object.keys(monthlyTotals).map((currencyKey, key) => (
          Object.keys(monthlyTotals[currencyKey]).map((month, index) => (
            <View style={styles.tableBlock} key={`${key}-${index}`}>
              <View style={styles.inlineBlock2}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text>{month} Total</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center", borderLeft: "0.1px solid #d0d6d9" }}>
                  <Text>{currencyKey + monthlyTotals[currencyKey][month].toFixed(2)}</Text>
                </View>
              </View>
            </View>
          ))
        ))}

        <Footer />
      </Page>

      <Page style={styles.body}>
        <Image style={styles.image} src={c1Logo} />
        <Text style={styles.title}>C1 Wealth</Text>
        <View style={styles.horizontalRule} />
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text>{name}</Text>
            <Text style={{ fontWeight: "bold" }}>Customer ID: {userid}</Text>
            <Text style={{ fontWeight: "bold" }}>ID / Passport: {idnumber}</Text>
          </View>
          <View style={{ flex: 1, textAlign: "right" }}>
            <Text style={{ fontWeight: "bold" }}>Date: {date}</Text>
          </View>
        </View>
        <Text style={styles.author}>{"Totals"}</Text>

        <View>
          <Text style={styles.text}>
            Total In Withdrawable Wallet:
          </Text>
          <View style={styles.inlineBlock3} wrap={false}>
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black" }}>
              <Text >USDT {parseFloat(totalUSDT).toFixed(2)} (est. {countryCurrencyDisplay}{exchangeRates ? parseFloat(exchangeRates['12'] * totalUSDT).toFixed(2) : '---'})</Text>
            </View>
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black" }}>
              <Text >{countryCurrencyDisplay} {totalZAR}</Text>
            </View>
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black" }}>
              <Text >BTC {totalBTC}</Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={styles.text}>
            Total In Products:
          </Text>
          <View style={styles.inlineBlock3} wrap={false}>
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black" }}>
              <Text >USDT {parseFloat(usdtProductTotal).toFixed(2)} (est. {countryCurrencyDisplay}{exchangeRates ? parseFloat(exchangeRates['12'] * usdtProductTotal).toFixed(2) : '---'})</Text>
            </View>
            <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center", border: "1px solid black" }}>
              <Text >BTC {btcProductTotal}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
}

export default PDFProductsBreakdown;
