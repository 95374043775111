import React, { useState } from 'react'
import LoanCalculator2 from './calculator'
import LoanHistory from './productHistory'
import { UserContext } from '../../../../UserContext'

function CryptoLTVLoan() {

    const{auth,user} = React.useContext(UserContext)

    const [userid, setUserid] = useState(null)
  
    React.useEffect(()=>{
      
      if(auth && user)
      {
        setUserid(user.user.userid)
      }
  
    },[auth])
  return (
    <div className='p-4'>
        
        <div className="grid p-4 mt-4">
  <LoanCalculator2 userid={userid} />
  <LoanHistory userid={userid} />
</div>
    </div>
  )
}

export default CryptoLTVLoan