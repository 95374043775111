import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

// import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import Terms from "../../assets/attachments/Terms.pdf"

import PropTypes from 'prop-types'
import Welcome from "../Containers/Products/ExternalPrducts/AIT/Welcome";
import CreateNewWallet from "../Containers/Products/ExternalPrducts/AIT/CreateNewWallet";
import FundingOptions from "../Containers/Products/ExternalPrducts/AIT/FundingOptions";
import NewDeposit from "../Containers/Products/ExternalPrducts/AIT/NewDeposit";
import SubmitForm from "../Containers/Products/ExternalPrducts/AIT/SubmitForm";
import ThankYouDeposit from "../Containers/Products/ExternalPrducts/AIT/ThankYouDeposit";
import ExchangeFrom from "../Containers/Products/ExternalPrducts/AIT/ExchangeFrom";
import ExchangeForm from "../Containers/Products/ExternalPrducts/AIT/ExchangeForm";


function ExternalProductModal({closeProductModal, displayModal}) {

  const{auth,user} = React.useContext(UserContext);

  React.useEffect( () => {

    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        getUserDetails(user.user.userid)
      }
    }
  }, [auth])

    const [step, setStep] = React.useState(1);

    const [selectedCurrency, setSelectedCurrency] = React.useState('usdt');
    const [selectedCurrencyDisplay, setSelectedCurrencyDisplay] = React.useState('usdt');
    const [countryCurrency, setCountryCurrency] = React.useState('ZAR');

    const getUserDetails = async (userid) => {
      var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            var mainString = res.data.info.country
            if(mainString && mainString.includes("Namibia"))
            {
              setCountryCurrency('NAD')
            }else{
           
            }
          }
        });
      }
    

    const changeStepProcess = async(newStep) => {
        setStep(newStep)
    }

    const changeSelectedFromCurrency = async(selectedCurrency) => {
    
      setSelectedCurrency(selectedCurrency)
      if(selectedCurrency === 'zar')
      {
        setSelectedCurrencyDisplay(countryCurrency)
      }else{
        setSelectedCurrencyDisplay(selectedCurrency)
      }
    }


    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
      border: '2px solid #00c4f4',
    };


  return (
    <div>
      <Modal
        open={displayModal}
        onClose={closeProductModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
     
        className="form-no-background custom-scrollbar"
      >
        <Box           sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust the width as needed
            maxHeight: '90vh', // Set a maximum height based on viewport height
            overflowY: 'auto', // Allow vertical scrolling for content
            background: 'rgba(5,0,5,0.8)',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            border: '2px solid #00c4f4',
          }}>
            <div className="external_deposit_modal">
                {step == 1 ?
                    <Welcome closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
                : step == 2 ?
                    <CreateNewWallet closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
            : step == 3 ?
            <FundingOptions closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
            : step == 4 ?
            <NewDeposit closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
            : step == 5 ?
            <SubmitForm closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
            : step == 6 ?
            <ThankYouDeposit closeProductModal={closeProductModal} changeStepProcess={changeStepProcess} />
            : step == 7 ?
            <ExchangeFrom changeSelectedFromCurrency={changeSelectedFromCurrency} changeStepProcess={changeStepProcess} selectedCurrency={selectedCurrency} countryCurrency={countryCurrency} />
            : step == 8 ?
            <ExchangeForm changeSelectedFromCurrency={changeSelectedFromCurrency} changeStepProcess={changeStepProcess} selectedCurrency={selectedCurrency} selectedCurrencyDisplay={selectedCurrencyDisplay} />
            : ''}
            </div>
        </Box>
      </Modal>
    </div>
  )
}

ExternalProductModal.propTypes = {
    closeProductModal: PropTypes.func,
    displayModal:PropTypes.any,
    title:PropTypes.any,
    description:PropTypes.any,
}

export default ExternalProductModal